import * as React from 'react'
import { request } from 'graphql-request'

import Krpano from 'components/Krpano'
import Logo from 'components/Logo'

function App() {
  const [apartmentsCMS, setApartmentsCMS] = React.useState(null)
  const [floorsStatus, setFloorsStatus] = React.useState(null)

  React.useEffect(() => {
    const fetchApartments = async () => {
      const { apartments, kaikki_kerrokset } = await request(
        'https://api-eu-central-1.graphcms.com/v2/ckkk5982yzi2h01xmd0jw2tcm/master',
        `
      {
        apartments {
          id
          apartmentNumber
          availabilityStatus
        }

        kaikki_kerrokset {
          id
          kerros
          avaa
        }
      }
    `,
      )

      setApartmentsCMS(apartments)
      setFloorsStatus(kaikki_kerrokset)
    }

    fetchApartments()
  }, [])

  return (
    <div className="App">
      <Logo />
      <Krpano apartmentsCMS={apartmentsCMS} floorsStatus={floorsStatus} />
    </div>
  )
}

export default App
