import * as React from 'react'
import styled from 'styled-components/macro'

import ArrowIcon from 'icons/arrow'

import { BUILDING_A_DATA } from 'data/buildingA'
import Floor8 from 'floor-planes/floor8'
import Floor9 from 'floor-planes/floor9'
import Collapsible from './Collapsible'
import Floor10 from 'floor-planes/floor10'
import Floor11 from 'floor-planes/floor11'
import Floor12 from 'floor-planes/floor12'
import Floor13 from 'floor-planes/floor13'
import Floor14 from 'floor-planes/floor14'
import Floor15 from 'floor-planes/floor15'
import Floor16 from 'floor-planes/floor16'
import { initialApartments } from 'data/initialApartments'

const floorComponents = {
  8: Floor8,
  9: Floor9,
  10: Floor10,
  11: Floor11,
  12: Floor12,
  13: Floor13,
  14: Floor14,
  15: Floor15,
  16: Floor16,
}

const apartment = {
  floor: 15,
  building: 'A',
  apartmentNumber: 132,
  sceneName: 'scene_15_krs_A132',
  sceneName_future: 'scene_15_krs_A132_future',
  name: 'A132',
  lookAt: '-160, 14.11, 120',
  face: 'south',

  relatedApartments: {
    8: 62,
    9: 72,
    10: 82,
    11: 92,
    12: 102,
    13: 112,
    14: 122,
    16: 142,
  },
}

export default function NavigationPanel({
  krpano,
  hLookAt,
  apartmentsCMS,
  floorsStatus,
}) {
  const unlockFloors = !!floorsStatus
    ? floorsStatus
        .filter(({ avaa }) => avaa === true)
        .map(({ kerros }) => kerros)
    : []
  const highestFloor = unlockFloors.includes(16)
  const [activeFloor, setActiveFloor] = React.useState(
    highestFloor ? initialApartments[16].floor : apartment.floor,
  )
  const [activeApartment, setActiveApartment] = React.useState(
    highestFloor ? initialApartments[16] : apartment,
  )
  const [collapse, setCollapse] = React.useState(false)
  const [index, setIndex] = React.useState(undefined)
  const [isSceneNameFuture, setIsSceneNameFuture] = React.useState(false)
  const [currentFace, setCurrentFace] = React.useState('')
  const floors = Object.keys(BUILDING_A_DATA)

  React.useEffect(() => {
    if (
      window.matchMedia('(orientation: landscape)').matches &&
      window.matchMedia('(max-device-width: 667px)').matches
    ) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [])

  const loadScene = React.useCallback(
    (sceneName, lookAt, activeFace) => {
      if (krpano) {
        const hlookat = krpano.get('view.hlookat')
        const vlookat = krpano.get('view.vlookat')
        const fov = krpano.get('view.fov')
        const distortion = krpano.get('view.distortion')

        krpano.call(
          `loadscene(${sceneName}, null, MERGE, BLEND(0.5, easeInCubic)); lookat(${lookAt});`,
        )

        if (currentFace === activeFace) {
          krpano.set('view.hlookat', hlookat)
          krpano.set('view.vlookat', vlookat)
          krpano.set('view.fov', fov)
          krpano.set('view.distortion', distortion)
        }
      }
    },
    [currentFace, krpano],
  )

  const changeView = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartment = BUILDING_A_DATA[floorNumber].find(
        e => e.apartmentNumber === aptNumber,
      )
      const lookAt = apartment.lookAt
      const activeFace = apartment.face
      const sceneName = isSceneNameFuture
        ? apartment.sceneName_future
        : apartment.sceneName
      setActiveApartment(apartment)
      setCurrentFace(activeFace)
      loadScene(sceneName, lookAt, activeFace)
    },
    [isSceneNameFuture, loadScene],
  )

  const changeFloor = navCase => {
    const { apartmentNumber, relatedApartments, floor } = activeApartment
    const targetedFloor = changeActiveFloor(navCase) || floor
    const targetedApartmentNumber =
      relatedApartments[targetedFloor] || apartmentNumber

    changeView(targetedFloor, targetedApartmentNumber)
  }

  const changeActiveFloor = navCase => {
    let targetedFloor

    if (
      navCase === 'up' &&
      unlockFloors.indexOf(activeFloor) < unlockFloors.length - 1
    ) {
      const nextIndex = unlockFloors.indexOf(activeFloor) + 1
      targetedFloor = unlockFloors[nextIndex]
      setActiveFloor(targetedFloor)
    }
    if (navCase === 'down' && unlockFloors.indexOf(activeFloor) > 0) {
      const nextIndex = unlockFloors.indexOf(activeFloor) - 1
      targetedFloor = unlockFloors[nextIndex]
      setActiveFloor(targetedFloor)
    }
    return targetedFloor
  }

  const changeApartInCollapsible = navCase => {
    const apartments = Object.values(BUILDING_A_DATA[activeFloor]).map(
      e => e.apartmentNumber,
    )
    const { apartmentNumber } = activeApartment
    const apartmentIndex = apartments.indexOf(apartmentNumber)

    if (navCase === 'next' && apartmentIndex < apartments.length - 1) {
      setIndex(apartmentIndex + 1)
      changeView(activeFloor, apartments[apartmentIndex + 1])
    }
    if (navCase === 'previous' && apartmentIndex > 0) {
      changeView(activeFloor, apartments[apartmentIndex - 1])
      setIndex(apartmentIndex - 1)
    }
  }

  React.useEffect(() => {
    // Initialize first view
    changeView(activeFloor, activeApartment.apartmentNumber)
  }, [activeApartment.apartmentNumber, activeFloor, changeView])

  const toggleSceneNameFuture = () => setIsSceneNameFuture(!isSceneNameFuture)

  const floorPlanProps = {
    activeApartment,
    changeFloor,
    changeView,
    hLookAt,
    toggleSceneNameFuture,
    isSceneNameFuture,
    apartmentsCMS,
  }

  const renderUnlockFloorComponents = () => {
    return !!unlockFloors
      ? unlockFloors.map(floorNumber => {
          const UnlockFloor = floorComponents[floorNumber]

          return (
            <UnlockFloor
              key={floorNumber}
              floor={floorNumber}
              {...floorPlanProps}
            />
          )
        })
      : null
  }

  return (
    <Wrapper>
      <Collapsible collapse={collapse} setCollapse={setCollapse}>
        {collapse ? (
          <ApartmentInfo>
            <ElevatorIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  onClick={() => changeFloor('up')}
                  disabled={activeFloor === Number(floors[floors.length - 1])}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="180deg"
                  onClick={() => changeFloor('down')}
                  disabled={activeFloor === Number(floors[0])}
                />
              </div>
            </ElevatorIcons>
            <p className="floor">Kerros {activeApartment.floor}</p>
            <p className="apartment">Huoneisto {activeApartment.name}</p>
            <ApartmentIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  rotateDeg="-90deg"
                  onClick={() => changeApartInCollapsible('next')}
                  disabled={index === 3}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="90deg"
                  onClick={() => changeApartInCollapsible('previous')}
                  disabled={index === 0}
                />
              </div>
            </ApartmentIcons>
          </ApartmentInfo>
        ) : (
          <div style={{ position: 'relative' }}>
            <SwitchComponents active={activeFloor}>
              {renderUnlockFloorComponents()}
            </SwitchComponents>
            <Link
              href="https://tietoa.fi/palvelut/sujuvat-sovellukset/tietoa-360-maisemakone/"
              target="_blank"
            >
              Tietoa 360 Maisemakone
            </Link>
          </div>
        )}
      </Collapsible>
    </Wrapper>
  )
}

function SwitchComponents({ active, children }) {
  return !!children
    ? children.filter(child => child.props.floor === active)
    : null
}

const Link = styled.a`
  position: absolute;
  right: -65px;
  top: 90px;
  transform: rotate(270deg);
  font-size: 12px;
  opacity: 0.5;
  text-decoration: none;
  color: #3f3f3c;

  @media (max-width: 575px) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 510px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  background-color: rgb(213, 212, 207);
  z-index: 2;

  @media (max-width: 575px) {
    right: 0;
    width: 100%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }
`

const ApartmentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    min-width: 50px;
    margin: 0;
    padding: 10px 20px;
    color: var(--light);
  }

  .floor {
    background-color: var(--primary-color);
  }

  .apartment {
    min-width: 120px;
    margin-right: 0;
    background-color: var(--orange);
  }

  @media (max-width: 575px) {
    p {
      padding: 5px 10px;
      font-size: 10px;
    }

    .apartment {
      min-width: 80px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--orange);
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--orange);
    }
  }
`

const ElevatorIcons = styled.div`
  display: flex;
  margin-right: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-right: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }
`
const ApartmentIcons = styled.div`
  display: flex;
  margin-left: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-left: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }
`
