import styled from 'styled-components'

import JMSuomiLogo from 'icons/jmsuomi-logo'

export default function Logo() {
  return (
    <Wrapper>
      <a href="https://www.jmoy.fi/" target="_blank" rel="noreferrer">
        <JMSuomiLogo />
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    left: 20px;
    right: auto;
  }
`
