import * as React from 'react'
import styled from 'styled-components'

export default function Floor15({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_x38_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.40681 136.39999"
      xmlSpace="preserve"
      enableBackground="new 0 0 210.40681 136.39999"
    >
      <g id="bg_00000042729004844499262860000010886423501504859058_">
        <g id="static_00000039133536715786956740000005349817368720373150_">
          <rect
            id="plate_00000002380536113432323800000017056954772951830163_"
            className="st0"
            width="210.40681"
            height="136.39999"
          />
        </g>
      </g>
      <g id="apartments_00000013899752667128033830000010950945144990582975_">
        <g
          onClick={() => changeView(15, 132)}
          onMouseOver={() => setElementOnHover('A132')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="_x31_42_00000173873865238697319850000000801819026155698109_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A132'
                ? 'active-apt'
                : elementOnHover === 'A132'
                ? 'on-hover'
                : ''
            }`}
            points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 131)}
          onMouseOver={() => setElementOnHover('A131')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_41_00000106834436105046478350000003137180890092963752_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A131'
                ? 'active-apt'
                : elementOnHover === 'A131'
                ? 'on-hover'
                : ''
            }`}
            points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 130)}
          onMouseOver={() => setElementOnHover('A130')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_40_00000140011348646828165830000003307111977077348742_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A130'
                ? 'active-apt'
                : elementOnHover === 'A130'
                ? 'on-hover'
                : ''
            }`}
            points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 128)}
          onMouseOver={() => setElementOnHover('A128')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_38_00000142163141099032026470000009300542373553019311_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A128'
                ? 'active-apt'
                : elementOnHover === 'A128'
                ? 'on-hover'
                : ''
            }`}
            points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 125)}
          onMouseOver={() => setElementOnHover('A125')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_35_00000068648478462883789000000016611648877225481401_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A125'
                ? 'active-apt'
                : elementOnHover === 'A125'
                ? 'on-hover'
                : ''
            }`}
            points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 124)}
          onMouseOver={() => setElementOnHover('A124')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_34_00000062170393121402309640000004858780391970502288_"
            className={`st1 ${
              sceneName === 'scene_15_krs_A124'
                ? 'active-apt'
                : elementOnHover === 'A124'
                ? 'on-hover'
                : ''
            }`}
            points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(15, 123)}
          onMouseOver={() => setElementOnHover('A123')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_33_00000089533305289851278050000002696146738916854161_"
            className={`st2 ${
              sceneName === 'scene_15_krs_A123'
                ? 'active-apt'
                : elementOnHover === 'A123'
                ? 'on-hover'
                : ''
            }`}
            points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000036236800537233344410000017908544044762701981_">
        <polygon
          id="_x31_33_00000110455940951170417450000011220389434867528846_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polyline
          id="_x31_42_00000173874997145136771210000017305393976768546236_"
          className="st3"
          points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
        />
        <polygon
          id="_x31_41_00000139266096085981981410000015165254184632650675_"
          className="st3"
          points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
        />
        <polygon
          id="_x31_40_00000084496248660502353260000017684161949357725872_"
          className="st3"
          points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
        />
        <polygon
          id="_x31_38_00000069400118776567944980000012651996948728181166_"
          className="st3"
          points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
        />
        <polygon
          id="_x31_35_00000132080126241487587740000001953967534718231735_"
          className="st3"
          points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
        />
        <polygon
          id="_x31_34_00000088105870513748665950000006327188723778958010_"
          className="st3"
          points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
        />
        <polygon
          id="_x31_33_00000101077673983949535040000007930390961169047738_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polygon
          id="runko_00000165228652965675239890000010556361160564206741_"
          className="st4"
          points="9.57856,19.58349
		10.03238,126.23048 36.35376,126.23048 36.25941,113.97742 120.30991,113.97742 120.30991,126.23048 146.17747,126.23048
		146.17747,24.12166 135.73969,24.12166 135.73969,9.59952 75.83585,9.59952 75.84483,19.58349 	"
        />
        <line
          className="st3"
          x1="10.0323782"
          y1="126.230484"
          x2="64.566803"
          y2="126.230484"
        />
        <line
          className="st3"
          x1="76.8172226"
          y1="54.9812164"
          x2="113.2555237"
          y2="54.9812164"
        />
        <line
          className="st3"
          x1="95.3499756"
          y1="54.9812164"
          x2="95.3499756"
          y2="9.5995159"
        />
        <line
          className="st3"
          x1="113.9564743"
          y1="63.1499214"
          x2="146.177475"
          y2="63.1499214"
        />
        <line
          className="st3"
          x1="49.3616867"
          y1="63.0061378"
          x2="57.7730293"
          y2="63.1499214"
        />
        <line
          className="st3"
          x1="64.566803"
          y1="63.1499214"
          x2="77.8780212"
          y2="63.1499214"
        />
        <line
          className="st3"
          x1="95.3499756"
          y1="63.1499214"
          x2="104.089325"
          y2="63.1499214"
        />
      </g>
      <g id="apartment_nr_00000078027841110497570430000000265275966103566997_">
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A123'
                ? 'white-text'
                : elementOnHover === 'A123'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 123)}
            onMouseOver={() => setElementOnHover('A123')}
            onMouseLeave={() => setElementOnHover('')}
            d="M18.25541,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H21.0181l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H18.25541z M19.85795,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H19.85795z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A123'
                ? 'white-text'
                : elementOnHover === 'A123'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 123)}
            onMouseOver={() => setElementOnHover('A123')}
            onMouseLeave={() => setElementOnHover('')}
            d="M23.01615,37.10205v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H23.01615z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A123'
                ? 'white-text'
                : elementOnHover === 'A123'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 123)}
            onMouseOver={() => setElementOnHover('A123')}
            onMouseLeave={() => setElementOnHover('')}
            d="M24.87064,37.10205v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H24.87064z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A123'
                ? 'white-text'
                : elementOnHover === 'A123'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 123)}
            onMouseOver={() => setElementOnHover('A123')}
            onMouseLeave={() => setElementOnHover('')}
            d="M28.35697,34.07275h-0.57129c0-0.20703,0.08545-0.38965,0.25635-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33154,0,0.59814,0.08691,0.79932,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61475-0.17773
			c0.11572-0.11133,0.17334-0.2832,0.17334-0.5166c0-0.44922-0.19385-0.67383-0.58154-0.67383
			c-0.23096,0-0.40137,0.06055-0.51074,0.18066C28.40092,33.84521,28.36088,33.95068,28.35697,34.07275z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A124'
                ? 'white-text'
                : elementOnHover === 'A124'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 124)}
            onMouseOver={() => setElementOnHover('A124')}
            onMouseLeave={() => setElementOnHover('')}
            d="M42.39408,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734H43.2642
			l-0.40137,1.27734H42.39408z M43.99662,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H43.99662z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A124'
                ? 'white-text'
                : elementOnHover === 'A124'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 124)}
            onMouseOver={() => setElementOnHover('A124')}
            onMouseLeave={() => setElementOnHover('')}
            d="M47.15482,37.10205v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H47.15482z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A124'
                ? 'white-text'
                : elementOnHover === 'A124'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 124)}
            onMouseOver={() => setElementOnHover('A124')}
            onMouseLeave={() => setElementOnHover('')}
            d="M49.00932,37.10205v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H49.00932z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A124'
                ? 'white-text'
                : elementOnHover === 'A124'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 124)}
            onMouseOver={() => setElementOnHover('A124')}
            onMouseLeave={() => setElementOnHover('')}
            d="M53.47709,37.10205v-1.06934H51.9185v-0.36328l1.5293-2.37012h0.48047v2.32617h0.65918v0.40723h-0.65918
			v1.06934H53.47709z M53.46488,33.8208l-1.13916,1.80469h1.15137V33.8208H53.46488z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A125'
                ? 'white-text'
                : elementOnHover === 'A125'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 125)}
            onMouseOver={() => setElementOnHover('A125')}
            onMouseLeave={() => setElementOnHover('')}
            d="M60.939,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H63.7017l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H60.939z M62.54154,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H62.54154z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A125'
                ? 'white-text'
                : elementOnHover === 'A125'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 125)}
            onMouseOver={() => setElementOnHover('A125')}
            onMouseLeave={() => setElementOnHover('')}
            d="M65.69975,37.10205v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H65.69975z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A125'
                ? 'white-text'
                : elementOnHover === 'A125'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 125)}
            onMouseOver={() => setElementOnHover('A125')}
            onMouseLeave={() => setElementOnHover('')}
            d="M67.55424,37.10205v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H67.55424z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A125'
                ? 'white-text'
                : elementOnHover === 'A125'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 125)}
            onMouseOver={() => setElementOnHover('A125')}
            onMouseLeave={() => setElementOnHover('')}
            d="M71.27201,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H71.27201z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M80.01127,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H80.01127z M81.61381,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H81.61381z"
          />
          <path
            className="st6"
            d="M84.77201,37.1084v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H84.77201z"
          />
          <path
            className="st6"
            d="M86.6265,37.1084v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547H86.6265c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H86.6265z"
          />
          <path
            className="st6"
            d="M90.14213,34.98242v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10791,0.64258-0.32373,0.85449s-0.49463,0.31836-0.83643,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C90.19291,34.29492,90.14213,34.60254,90.14213,34.98242z M90.21244,35.99512c0,0.24805,0.06445,0.44727,0.19385,0.59766
			c0.13477,0.1582,0.31885,0.2373,0.55176,0.2373c0.46191,0,0.69287-0.27637,0.69287-0.8291
			c0-0.56445-0.23486-0.84668-0.70459-0.84668c-0.22705,0-0.40625,0.07715-0.53711,0.2334
			C90.27787,35.54199,90.21244,35.74512,90.21244,35.99512z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M98.80326,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H98.80326z M100.4058,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H100.4058z"
          />
          <path
            className="st6"
            d="M103.564,37.1084v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H103.564z"
          />
          <path
            className="st6"
            d="M105.4185,37.1084v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H105.4185z"
          />
          <path
            className="st6"
            d="M108.77006,37.1084l1.45313-3.39551h-1.74609v-0.40723h2.23828v0.38379l-1.44727,3.41895H108.77006z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A128'
                ? 'white-text'
                : elementOnHover === 'A128'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 128)}
            onMouseOver={() => setElementOnHover('A128')}
            onMouseLeave={() => setElementOnHover('')}
            d="M123.42191,37.23438l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,33.51953l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A128'
                ? 'white-text'
                : elementOnHover === 'A128'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 128)}
            onMouseOver={() => setElementOnHover('A128')}
            onMouseLeave={() => setElementOnHover('')}
            d="M128.18266,37.23438V33.8916l-0.72071,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H128.18266z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A128'
                ? 'white-text'
                : elementOnHover === 'A128'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 128)}
            onMouseOver={() => setElementOnHover('A128')}
            onMouseLeave={() => setElementOnHover('')}
            d="M130.03716,37.23438v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H130.03716z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A128'
                ? 'white-text'
                : elementOnHover === 'A128'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 128)}
            onMouseOver={() => setElementOnHover('A128')}
            onMouseLeave={() => setElementOnHover('')}
            d="M134.20512,37.30176c-0.79199,0-1.18848-0.3623-1.18848-1.08789c0-0.52344,0.23828-0.83594,0.71387-0.9375
			v-0.0127c-0.42285-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29297-0.69922
			c0.20313-0.18164,0.47852-0.27246,0.82813-0.27246c0.32715,0,0.59277,0.08398,0.7959,0.25195
			c0.20801,0.17578,0.3125,0.41113,0.3125,0.70801c0,0.28906-0.0752,0.52051-0.22461,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41602,0.24512v0.0127c0.17383,0.0332,0.3252,0.11426,0.4541,0.24316
			c0.17285,0.17578,0.25977,0.41309,0.25977,0.71191C135.39848,36.94531,135.00102,37.30176,134.20512,37.30176z
			 M133.48247,36.18457c0,0.51465,0.24707,0.77148,0.74023,0.77148c0.47363,0,0.70996-0.25488,0.70996-0.7666
			c0-0.49805-0.24219-0.74805-0.72754-0.74805c-0.22559,0-0.40234,0.06543-0.53027,0.19629
			C133.54692,35.76758,133.48247,35.9502,133.48247,36.18457z M133.56157,34.41309c0,0.45508,0.22461,0.68262,0.67285,0.68262
			c0.19336,0,0.34668-0.05957,0.45801-0.17969c0.11133-0.11914,0.16699-0.28516,0.16699-0.49707
			c0-0.47266-0.21777-0.70898-0.6543-0.70898c-0.20215,0-0.35938,0.06348-0.47266,0.19141
			C133.61821,34.02832,133.56157,34.19922,133.56157,34.41309z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M123.42191,75.45313l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,71.73828l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className="st6"
            d="M128.18266,75.45313v-3.34277l-0.72071,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H128.18266z"
          />
          <path
            className="st6"
            d="M130.03716,75.45313v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H130.03716z"
          />
          <path
            className="st6"
            d="M134.90923,73.77051v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C134.63872,75.1748,134.90923,74.70703,134.90923,73.77051z M133.4063,72.74805c0,0.56738,0.23242,0.85059,0.69629,0.85059
			c0.23047,0,0.41211-0.07715,0.54297-0.23242s0.19629-0.35938,0.19629-0.6123c0-0.24414-0.06348-0.44141-0.19043-0.59082
			c-0.13477-0.15625-0.32031-0.23438-0.55469-0.23438C133.63676,71.92871,133.4063,72.20215,133.4063,72.74805z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A130'
                ? 'white-text'
                : elementOnHover === 'A130'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 130)}
            onMouseOver={() => setElementOnHover('A130')}
            onMouseLeave={() => setElementOnHover('')}
            d="M114.2017,101.2334l1.3208-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H114.2017z M115.80375,97.51855l-0.60645,2.04785h1.22461l-0.60645-2.04785H115.80375z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A130'
                ? 'white-text'
                : elementOnHover === 'A130'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 130)}
            onMouseOver={() => setElementOnHover('A130')}
            onMouseLeave={() => setElementOnHover('')}
            d="M118.96195,101.2334v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H118.96195z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A130'
                ? 'white-text'
                : elementOnHover === 'A130'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 130)}
            onMouseOver={() => setElementOnHover('A130')}
            onMouseLeave={() => setElementOnHover('')}
            d="M121.30277,98.2041h-0.57129c0-0.20703,0.08594-0.38965,0.25684-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33105,0,0.59766,0.08691,0.79883,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61523-0.17773
			c0.11523-0.11133,0.17285-0.2832,0.17285-0.5166c0-0.44922-0.19336-0.67383-0.58105-0.67383
			c-0.23145,0-0.40137,0.06055-0.51074,0.18066C121.34672,97.97656,121.30668,98.08203,121.30277,98.2041z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A130'
                ? 'white-text'
                : elementOnHover === 'A130'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 130)}
            onMouseOver={() => setElementOnHover('A130')}
            onMouseLeave={() => setElementOnHover('')}
            d="M124.98539,101.30078c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S125.84867,101.30078,124.98539,101.30078z M124.15629,99.33203
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S124.15629,98.25,124.15629,99.33203z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A131'
                ? 'white-text'
                : elementOnHover === 'A131'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 131)}
            onMouseOver={() => setElementOnHover('A131')}
            onMouseLeave={() => setElementOnHover('')}
            d="M74.12113,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H74.12113z M75.72367,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H75.72367z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A131'
                ? 'white-text'
                : elementOnHover === 'A131'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 131)}
            onMouseOver={() => setElementOnHover('A131')}
            onMouseLeave={() => setElementOnHover('')}
            d="M78.88187,101.28223v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H78.88187z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A131'
                ? 'white-text'
                : elementOnHover === 'A131'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 131)}
            onMouseOver={() => setElementOnHover('A131')}
            onMouseLeave={() => setElementOnHover('')}
            d="M81.22269,98.25293h-0.57129c0-0.20703,0.08545-0.38965,0.25635-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33154,0,0.59814,0.08691,0.79932,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61475-0.17773
			c0.11572-0.11133,0.17334-0.2832,0.17334-0.5166c0-0.44922-0.19385-0.67383-0.58154-0.67383
			c-0.23096,0-0.40137,0.06055-0.51074,0.18066C81.26664,98.02539,81.2266,98.13086,81.22269,98.25293z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A131'
                ? 'white-text'
                : elementOnHover === 'A131'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 131)}
            onMouseOver={() => setElementOnHover('A131')}
            onMouseLeave={() => setElementOnHover('')}
            d="M84.88187,101.28223v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H84.88187z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A132'
                ? 'white-text'
                : elementOnHover === 'A132'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 132)}
            onMouseOver={() => setElementOnHover('A132')}
            onMouseLeave={() => setElementOnHover('')}
            d="M27.80033,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H27.80033z M29.40287,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H29.40287z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A132'
                ? 'white-text'
                : elementOnHover === 'A132'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 132)}
            onMouseOver={() => setElementOnHover('A132')}
            onMouseLeave={() => setElementOnHover('')}
            d="M32.56107,101.28223v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H32.56107z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A132'
                ? 'white-text'
                : elementOnHover === 'A132'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 132)}
            onMouseOver={() => setElementOnHover('A132')}
            onMouseLeave={() => setElementOnHover('')}
            d="M34.90189,98.25293H34.3306c0-0.20703,0.08545-0.38965,0.25635-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33154,0,0.59814,0.08691,0.79932,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61475-0.17773
			c0.11572-0.11133,0.17334-0.2832,0.17334-0.5166c0-0.44922-0.19385-0.67383-0.58154-0.67383
			c-0.23096,0-0.40137,0.06055-0.51074,0.18066C34.94584,98.02539,34.9058,98.13086,34.90189,98.25293z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_15_krs_A132'
                ? 'white-text'
                : elementOnHover === 'A132'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(15, 132)}
            onMouseOver={() => setElementOnHover('A132')}
            onMouseLeave={() => setElementOnHover('')}
            d="M37.41557,101.28223v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H37.41557z"
          />
        </g>
      </g>
      <g
        id="state_00000007391754229939552890000008535805942998059433_"
        className="switch"
        onClick={toggleSceneNameFuture}
      >
        <path
          id="plate_00000081640739306804050840000007853620084359890331_"
          className="st6"
          d="M169.53586,117.0297h-0.00002
		c-5.59163,0-10.16658-4.57496-10.16658-10.16658V91.3745c0-5.59162,4.57495-10.16658,10.16658-10.16658h0.00002
		c5.59161,0,10.16658,4.57496,10.16658,10.16658v15.48862C179.70244,112.45474,175.12747,117.0297,169.53586,117.0297z"
        />
        {isSceneNameFuture ? (
          <g id="_x38__00000159463500401715549290000012413271655622683299_">
            <path
              className="st6"
              d="M184.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H184.93462z"
            />
            <path
              className="st6"
              d="M190.49321,108.73438c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S191.64458,108.73438,190.49321,108.73438z M189.38774,106.10938
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.38774,104.66602,189.38774,106.10938z"
            />
            <path
              className="st6"
              d="M192.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H192.93462z"
            />
            <path
              className="st6"
              d="M196.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H196.93462z"
            />
            <path
              className="st6"
              d="M200.90727,106.92578v-0.54688h2.43359v0.54688H200.90727z"
            />
          </g>
        ) : (
          <g id="_x38__00000038377067502253803190000016846278791071137726_">
            <path
              className="st6"
              d="M185.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H185.32036z"
            />
            <path
              className="st6"
              d="M190.87895,93.97949c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S192.03032,93.97949,190.87895,93.97949z M189.77348,91.35449
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.77348,89.91113,189.77348,91.35449z"
            />
            <path
              className="st6"
              d="M193.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H193.32036z"
            />
            <path
              className="st6"
              d="M198.8477,93.88965v-4.45703l-0.96094,0.64844v-0.60547l1.04688-0.65625h0.51172v5.07031H198.8477z"
            />
          </g>
        )}

        {isSceneNameFuture ? (
          <g
            id="down_00000049938535706543404930000002636367116523620006_"
            className="switch"
            onClick={toggleSceneNameFuture}
          >
            <path
              className="st0"
              d="M169.53584,98.9855c-4.12483,0-7.39626,3.27142-7.39626,7.39626s3.27142,7.39626,7.39626,7.39626
			c3.98262,0,7.39627-3.27142,7.39627-7.39626S173.51846,98.9855,169.53584,98.9855z"
            />
          </g>
        ) : (
          <g
            id="up_00000173146352895130668910000008754833195979915698_"
            className="switch"
            onClick={toggleSceneNameFuture}
          >
            <path
              className="st0"
              d="M169.53584,84.19297c-4.12483,0-7.39626,3.27142-7.39626,7.39626c0,4.12485,3.27142,7.39627,7.39626,7.39627
			c3.98262,0,7.39627-3.27142,7.39627-7.39627C176.93211,87.46439,173.51846,84.19297,169.53584,84.19297z"
            />
          </g>
        )}
      </g>
      <g id="view_00000070102871090094820000000015324809775469972134_">
        {apartmentNumber === 123 ? (
          <g id="A123">
            <linearGradient
              id="A123cone_00000164481199504390482260000000300399057256608416_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="184.40488"
              x2="187.5202"
              y2="139.49487"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A123cone"
              style={{
                fill: 'url(#A123cone_00000164481199504390482260000000300399057256608416_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M23.19704,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L23.19704,17.25664z"
            />
            <circle
              id="A123_00000170260421143517371540000006390352550248815033_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 124 ? (
          <g id="A124">
            <linearGradient
              id="A124cone_00000106840733806768125250000012747961308149798295_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="206.65987"
              x2="187.5202"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A124cone"
              style={{
                fill: 'url(#A124cone_00000106840733806768125250000012747961308149798295_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M45.45204,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,17.25664z"
            />
            <circle
              id="A124_00000010274664603588313100000001020881717671855511_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 125 ? (
          <g id="A125">
            <linearGradient
              id="A125cone_00000005952089253522411340000004497594731688177839_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="224.35446"
              x2="187.5202"
              y2="179.44446"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A125cone"
              style={{
                fill: 'url(#A125cone_00000005952089253522411340000004497594731688177839_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M63.14662,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L63.14662,17.25664z"
            />
            <circle
              id="A125_00000131365512746783618100000003119430277507508152_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 128 ? (
          <g id="A128">
            <linearGradient
              id="A128cone_00000157300171936970958630000009830170117857340589_"
              gradientUnits="userSpaceOnUse"
              x1="189.84561"
              y1="297.95212"
              x2="189.84561"
              y2="253.0421"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A128cone"
              style={{
                fill: 'url(#A128cone_00000157300171936970958630000009830170117857340589_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M136.74428,19.58205
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L136.74428,19.58205z"
            />
            <circle
              id="A128_00000137814341243715654090000000393374832758881967_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 130 ? (
          <g id="A130">
            <linearGradient
              id="A130cone_00000176011764618120197890000016318595634333263767_"
              gradientUnits="userSpaceOnUse"
              x1="293.59818"
              y1="261.71448"
              x2="293.59818"
              y2="216.80447"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A130cone"
              style={{
                fill: 'url(#A130cone_00000176011764618120197890000016318595634333263767_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M100.50665,123.33463
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L100.50665,123.33463z"
            />
            <circle
              id="A130_00000081621552484873302700000014518020211121237675_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 131 ? (
          <g id="A131">
            <linearGradient
              id="A131cone_00000023242945112343659910000007607195660263064738_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="235.11095"
              x2="294.20319"
              y2="190.20094"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A131cone"
              style={{
                fill: 'url(#A131cone_00000023242945112343659910000007607195660263064738_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M73.90311,123.93962
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L73.90311,123.93962z"
            />
            <circle
              id="A131_00000117667096614740565620000013548120909801259455_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 132 ? (
          <g id="A132">
            <linearGradient
              id="A132cone_00000036945031397363995800000011577559556641888173_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="206.65987"
              x2="294.20319"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A132cone"
              style={{
                fill: 'url(#A132cone_00000036945031397363995800000011577559556641888173_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M45.45204,123.93962
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,123.93962z"
            />
            <circle
              id="A132_00000145761155786290269830000013745368607117790617_"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_00000145024287874124877580000014335646871158752942_">
        <path
          id="plate_00000161617636031727903610000004908188068179552688_"
          className="st6"
          d="M169.5358582,73.9096298h-0.0000153
		c-5.591629,0-10.1665802-4.5749664-10.1665802-10.166584V29.3607006c0-5.5916195,4.5749512-10.1665802,10.1665802-10.1665802
		h0.0000153c5.5916138,0,10.1665802,4.5749607,10.1665802,10.1665802v34.3823471
		C179.7024384,69.3346634,175.1274719,73.9096298,169.5358582,73.9096298z"
        />
        <g
          id="_x38__00000088831456222834310130000017574609303298092471_"
          className="st5"
        >
          <path
            className="st7"
            d="M167.5039063,48.9801636v-4.4570313l-0.9609375,0.6484375v-0.6054688l1.046875-0.65625h0.5117188v5.0703125
			H167.5039063z"
          />
          <path
            className="st7"
            d="M170.9335938,44.4528198l-0.0703125,1.6992188c0.2529297-0.1640625,0.5332031-0.2460938,0.8398438-0.2460938
			c0.5,0,0.8847656,0.15625,1.1523438,0.4697266c0.2451172,0.2890625,0.3671875,0.6708984,0.3671875,1.1455078
			c0,0.4951172-0.1582031,0.8808594-0.4765625,1.1582031c-0.296875,0.2597656-0.6845703,0.390625-1.1640625,0.390625
			c-0.5566406,0-0.9736328-0.1474609-1.25-0.4433594c-0.2001953-0.2109375-0.3007813-0.4462891-0.3007813-0.7050781h0.7109375
			c0,0.1591797,0.0615234,0.3046875,0.1845703,0.4375c0.1533203,0.1669922,0.3701172,0.25,0.6494141,0.25
			c0.6835938,0,1.0253906-0.3681641,1.0253906-1.1054688c0-0.3955078-0.1005859-0.6943359-0.3007813-0.8945313
			c-0.1699219-0.1669922-0.3837891-0.25-0.6425781-0.25c-0.3574219,0-0.625,0.1337891-0.8027344,0.4023438h-0.609375
			l0.1367188-2.8515625h2.5117188v0.5429688H170.9335938z"
          />
        </g>
        <g
          id="down_00000107578655021895712460000006737610893678276024_"
          onClick={() => changeFloor('down')}
        >
          <path
            className="st0"
            d="M169.5358429,55.3946877c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962669
			s3.2714233,7.3962631,7.3962555,7.3962631c3.9826202,0,7.3962708-3.2714233,7.3962708-7.3962631
			S173.5184631,55.3946877,169.5358429,55.3946877z"
          />
          <path
            className="st6"
            d="M174.2296143,61.3685951l0.426712,0.568943l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8359985-3.2714233l0.426712-0.568943l4.5515442,2.9869576
			c0.1422424,0.1422348,0.2844543,0.1422348,0.426712,0L174.2296143,61.3685951z"
          />
        </g>
        <g
          id="up_00000047756351874899859270000000498040182784504727_"
          onClick={() => changeFloor('up')}
        >
          <path
            className="st0"
            d="M169.5358429,22.573082c-4.1248322,0-7.3962555,3.2714252-7.3962555,7.3962631
			s3.2714233,7.3962669,7.3962555,7.3962669c3.9826202,0,7.3962708-3.2714233,7.3962708-7.3962669
			S173.5184631,22.573082,169.5358429,22.573082z"
          />
          <path
            className="st6"
            d="M174.2296143,31.3917046l0.426712-0.568943l-4.8360138-3.2714233
			c-0.1422424-0.1422367-0.426712-0.1422367-0.5689392,0l-4.8359985,3.2714233l0.426712,0.568943l4.5515442-2.9869537
			c0.1422424-0.1422367,0.2844543-0.1422367,0.426712,0L174.2296143,31.3917046z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  123: [27.79705, 14.48664],
  124: [50.05205, 14.48664],
  125: [67.74663, 14.48664],
  128: [141.34428, 16.81205],
  130: [105.10666, 120.56463],
  131: [78.50312, 121.16962],
  132: [50.05205, 121.16962],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e7e7e7;
  }
  .st2 {
    fill: #e7e7e7;
    stroke: #3f3f3c;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
  .st5 {
    enable-background: new;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    fill: #ffffff;
  }
  .st8 {
    fill: url(#A123cone_00000114761210806549481700000000989934686690763964_);
  }
  .st9 {
    fill: #c65805;
  }
  .st10 {
    fill: url(#A124cone_00000117670255914745233210000000084301607518604976_);
  }
  .st11 {
    fill: url(#A125cone_00000053534067410657320570000014582452495664327607_);
  }
  .st12 {
    fill: url(#A128cone_00000086692226012902934860000002686882018439499656_);
  }
  .st13 {
    fill: url(#A130cone_00000098189878658657397970000001280118392518884518_);
  }
  .st14 {
    fill: url(#A131cone_00000085933855722561938370000001895795281873571998_);
  }
  .st15 {
    fill: url(#A132cone_00000005266196350664577400000018398610703612096445_);
  }

  #_x31_33_00000089533305289851278050000002696146738916854161_,
  #_x31_34_00000062170393121402309640000004858780391970502288_,
  #_x31_35_00000068648478462883789000000016611648877225481401_,
  #_x31_38_00000142163141099032026470000009300542373553019311_,
  #_x31_40_00000140011348646828165830000003307111977077348742_,
  #_x31_41_00000106834436105046478350000003137180890092963752_,
  #_x31_42_00000173873865238697319850000000801819026155698109_ {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: #c65805;
    }
  }

  #down_00000049938535706543404930000002636367116523620006_:hover,
  #up_00000173146352895130668910000008754833195979915698_:hover,
  #down_00000107578655021895712460000006737610893678276024_:hover,
  #up_00000047756351874899859270000000498040182784504727_:hover {
    cursor: pointer;

    .st0 {
      fill: #c65805;
    }

    .st6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: #c65805;
  }

  .on-hover {
    fill: #c65805;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .larger-text {
    transform: scale(1.3);
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  .position {
    transform: translateX(-1px) scale(0.9);
    /* transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-box: fill-box; */
  }

  .sold {
    fill: #af6967;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
