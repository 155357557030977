const FLOOR_8_DATA = [
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 53,
    sceneName: 'scene_08_krs_A053',
    sceneName_future: 'scene_08_krs_A053_future',
    name: 'A053',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      9: 63,
      10: 73,
      11: 83,
      12: 93,
      13: 103,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 54,
    sceneName: 'scene_08_krs_A054',
    sceneName_future: 'scene_08_krs_A054_future',
    name: 'A054',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      9: 64,
      10: 74,
      11: 84,
      12: 94,
      13: 104,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 55,
    sceneName: 'scene_08_krs_A055',
    sceneName_future: 'scene_08_krs_A055_future',
    name: 'A055',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      9: 65,
      10: 75,
      11: 85,
      12: 95,
      13: 105,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 58,
    sceneName: 'scene_08_krs_A058',
    sceneName_future: 'scene_08_krs_A058_future',
    name: 'A058',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      9: 68,
      10: 78,
      11: 88,
      12: 98,
      13: 108,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 60,
    sceneName: 'scene_08_krs_A060',
    sceneName_future: 'scene_08_krs_A060_future',
    name: 'A060',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      9: 70,
      10: 80,
      11: 90,
      12: 100,
      13: 110,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 61,
    sceneName: 'scene_08_krs_A061',
    sceneName_future: 'scene_08_krs_A061_future',
    name: 'A061',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      9: 71,
      10: 81,
      11: 91,
      12: 101,
      13: 111,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 62,
    sceneName: 'scene_08_krs_A062',
    sceneName_future: 'scene_08_krs_A062_future',
    name: 'A062',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_9_DATA = [
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 63,
    sceneName: 'scene_09_krs_A063',
    sceneName_future: 'scene_09_krs_A063_future',
    name: 'A063',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      10: 73,
      11: 83,
      12: 93,
      13: 103,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 64,
    sceneName: 'scene_09_krs_A064',
    sceneName_future: 'scene_09_krs_A064_future',
    name: 'A064',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      10: 74,
      11: 84,
      12: 94,
      13: 104,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 65,
    sceneName: 'scene_09_krs_A065',
    sceneName_future: 'scene_09_krs_A065_future',
    name: 'A065',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      10: 75,
      11: 85,
      12: 95,
      13: 105,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 68,
    sceneName: 'scene_09_krs_A068',
    sceneName_future: 'scene_09_krs_A068_future',
    name: 'A068',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      10: 78,
      11: 88,
      12: 98,
      13: 108,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 70,
    sceneName: 'scene_09_krs_A070',
    sceneName_future: 'scene_09_krs_A070_future',
    name: 'A070',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      10: 80,
      11: 90,
      12: 100,
      13: 110,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 71,
    sceneName: 'scene_09_krs_A071',
    sceneName_future: 'scene_09_krs_A071_future',
    name: 'A071',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      10: 81,
      11: 91,
      12: 101,
      13: 111,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 72,
    sceneName: 'scene_09_krs_A072',
    sceneName_future: 'scene_09_krs_A072_future',
    name: 'A072',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_10_DATA = [
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 73,
    sceneName: 'scene_10_krs_A073',
    sceneName_future: 'scene_10_krs_A073_future',
    name: 'A073',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      11: 83,
      12: 93,
      13: 103,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 74,
    sceneName: 'scene_10_krs_A074',
    sceneName_future: 'scene_10_krs_A074_future',
    name: 'A074',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      11: 84,
      12: 94,
      13: 104,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 75,
    sceneName: 'scene_10_krs_A075',
    sceneName_future: 'scene_10_krs_A075_future',
    name: 'A075',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      11: 85,
      12: 95,
      13: 105,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 78,
    sceneName: 'scene_10_krs_A078',
    sceneName_future: 'scene_10_krs_A078_future',
    name: 'A078',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      11: 88,
      12: 98,
      13: 108,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 80,
    sceneName: 'scene_10_krs_A080',
    sceneName_future: 'scene_10_krs_A080_future',
    name: 'A080',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      11: 90,
      12: 100,
      13: 110,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 81,
    sceneName: 'scene_10_krs_A081',
    sceneName_future: 'scene_10_krs_A081_future',
    name: 'A081',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      11: 91,
      12: 101,
      13: 111,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 82,
    sceneName: 'scene_10_krs_A082',
    sceneName_future: 'scene_10_krs_A082_future',
    name: 'A082',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_11_DATA = [
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 83,
    sceneName: 'scene_11_krs_A083',
    sceneName_future: 'scene_11_krs_A083_future',
    name: 'A083',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      12: 93,
      13: 103,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 84,
    sceneName: 'scene_11_krs_A084',
    sceneName_future: 'scene_11_krs_A084_future',
    name: 'A084',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      12: 94,
      13: 104,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 85,
    sceneName: 'scene_11_krs_A085',
    sceneName_future: 'scene_11_krs_A085_future',
    name: 'A085',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      12: 95,
      13: 105,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 88,
    sceneName: 'scene_11_krs_A088',
    sceneName_future: 'scene_11_krs_A088_future',
    name: 'A088',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      12: 98,
      13: 108,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 90,
    sceneName: 'scene_11_krs_A090',
    sceneName_future: 'scene_11_krs_A090_future',
    name: 'A090',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      12: 100,
      13: 110,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 91,
    sceneName: 'scene_11_krs_A091',
    sceneName_future: 'scene_11_krs_A091_future',
    name: 'A091',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      12: 101,
      13: 111,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 92,
    sceneName: 'scene_11_krs_A092',
    sceneName_future: 'scene_11_krs_A092_future',
    name: 'A092',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_12_DATA = [
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 93,
    sceneName: 'scene_12_krs_A093',
    sceneName_future: 'scene_12_krs_A093_future',
    name: 'A093',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      11: 83,
      13: 103,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 94,
    sceneName: 'scene_12_krs_A094',
    sceneName_future: 'scene_12_krs_A094_future',
    name: 'A094',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      11: 84,
      13: 104,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 95,
    sceneName: 'scene_12_krs_A095',
    sceneName_future: 'scene_12_krs_A095_future',
    name: 'A095',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      11: 85,
      13: 105,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 98,
    sceneName: 'scene_12_krs_A098',
    sceneName_future: 'scene_12_krs_A098_future',
    name: 'A098',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      11: 88,
      13: 108,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 100,
    sceneName: 'scene_12_krs_A100',
    sceneName_future: 'scene_12_krs_A100_future',
    name: 'A0100',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      11: 90,
      13: 110,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 101,
    sceneName: 'scene_12_krs_A101',
    sceneName_future: 'scene_12_krs_A101_future',
    name: 'A0101',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      11: 91,
      13: 111,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 102,
    sceneName: 'scene_12_krs_A102',
    sceneName_future: 'scene_12_krs_A102_future',
    name: 'A0102',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_13_DATA = [
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 103,
    sceneName: 'scene_13_krs_A103',
    sceneName_future: 'scene_13_krs_A103_future',
    name: 'A103',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      11: 83,
      12: 93,
      14: 113,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 104,
    sceneName: 'scene_13_krs_A104',
    sceneName_future: 'scene_13_krs_A104_future',
    name: 'A104',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      11: 84,
      12: 94,
      14: 114,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 105,
    sceneName: 'scene_13_krs_A105',
    sceneName_future: 'scene_13_krs_A105_future',
    name: 'A105',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      11: 85,
      12: 95,
      14: 115,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 108,
    sceneName: 'scene_13_krs_A108',
    sceneName_future: 'scene_13_krs_A108_future',
    name: 'A0108',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      11: 88,
      12: 98,
      14: 118,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 110,
    sceneName: 'scene_13_krs_A110',
    sceneName_future: 'scene_13_krs_A110_future',
    name: 'A110',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      11: 90,
      12: 100,
      14: 120,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 111,
    sceneName: 'scene_13_krs_A111',
    sceneName_future: 'scene_13_krs_A111_future',
    name: 'A111',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      11: 91,
      12: 101,
      14: 121,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 112,
    sceneName: 'scene_13_krs_A112',
    sceneName_future: 'scene_13_krs_A112_future',
    name: 'A112',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      14: 122,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_14_DATA = [
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 113,
    sceneName: 'scene_14_krs_A113',
    sceneName_future: 'scene_14_krs_A113_future',
    name: 'A113',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      11: 83,
      12: 93,
      13: 103,
      15: 123,
      16: 133,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 114,
    sceneName: 'scene_14_krs_A114',
    sceneName_future: 'scene_14_krs_A114_future',
    name: 'A114',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      11: 84,
      12: 94,
      13: 104,
      15: 124,
      16: 134,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 115,
    sceneName: 'scene_14_krs_A115',
    sceneName_future: 'scene_14_krs_A115_future',
    name: 'A115',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      11: 85,
      12: 95,
      13: 105,
      15: 125,
      16: 135,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 118,
    sceneName: 'scene_14_krs_A118',
    sceneName_future: 'scene_14_krs_A118_future',
    name: 'A0118',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      11: 88,
      12: 98,
      13: 108,
      15: 128,
      16: 138,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 120,
    sceneName: 'scene_14_krs_A120',
    sceneName_future: 'scene_14_krs_A120_future',
    name: 'A120',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      11: 90,
      12: 100,
      13: 110,
      15: 130,
      16: 140,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 121,
    sceneName: 'scene_14_krs_A121',
    sceneName_future: 'scene_14_krs_A121_future',
    name: 'A121',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      11: 91,
      12: 101,
      13: 111,
      15: 131,
      16: 141,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 122,
    sceneName: 'scene_14_krs_A122',
    sceneName_future: 'scene_14_krs_A122_future',
    name: 'A122',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      15: 132,
      16: 142,
    },
  },
]

const FLOOR_15_DATA = [
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 123,
    sceneName: 'scene_15_krs_A123',
    sceneName_future: 'scene_15_krs_A123_future',
    name: 'A123',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      11: 83,
      12: 93,
      13: 103,
      14: 113,
      16: 133,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 124,
    sceneName: 'scene_15_krs_A124',
    sceneName_future: 'scene_15_krs_A124_future',
    name: 'A124',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      11: 84,
      12: 94,
      13: 104,
      14: 114,
      16: 134,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 125,
    sceneName: 'scene_15_krs_A125',
    sceneName_future: 'scene_15_krs_A125_future',
    name: 'A125',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      11: 85,
      12: 95,
      13: 105,
      14: 115,
      16: 135,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 128,
    sceneName: 'scene_15_krs_A128',
    sceneName_future: 'scene_15_krs_A128_future',
    name: 'A0128',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      11: 88,
      12: 98,
      13: 108,
      14: 118,
      16: 138,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 130,
    sceneName: 'scene_15_krs_A130',
    sceneName_future: 'scene_15_krs_A130_future',
    name: 'A130',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      11: 90,
      12: 100,
      13: 110,
      14: 120,
      16: 140,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 131,
    sceneName: 'scene_15_krs_A131',
    sceneName_future: 'scene_15_krs_A131_future',
    name: 'A131',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      11: 91,
      12: 101,
      13: 111,
      14: 121,
      16: 141,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 132,
    sceneName: 'scene_15_krs_A132',
    sceneName_future: 'scene_15_krs_A132_future',
    name: 'A132',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      16: 142,
    },
  },
]

const FLOOR_16_DATA = [
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 133,
    sceneName: 'scene_16_krs_A133',
    sceneName_future: 'scene_16_krs_A133_future',
    name: 'A133',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 53,
      9: 63,
      10: 73,
      11: 83,
      12: 93,
      13: 103,
      14: 113,
      15: 123,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 134,
    sceneName: 'scene_16_krs_A134',
    sceneName_future: 'scene_16_krs_A134_future',
    name: 'A134',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 54,
      9: 64,
      10: 74,
      11: 84,
      12: 94,
      13: 104,
      14: 114,
      15: 124,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 135,
    sceneName: 'scene_16_krs_A135',
    sceneName_future: 'scene_16_krs_A135_future',
    name: 'A135',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 55,
      9: 65,
      10: 75,
      11: 85,
      12: 95,
      13: 105,
      14: 115,
      15: 125,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 138,
    sceneName: 'scene_16_krs_A138',
    sceneName_future: 'scene_16_krs_A138_future',
    name: 'A0138',
    lookAt: '20, 14.11, 120',
    face: 'north',

    relatedApartments: {
      8: 58,
      9: 68,
      10: 78,
      11: 88,
      12: 98,
      13: 108,
      14: 118,
      15: 128,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 140,
    sceneName: 'scene_16_krs_A140',
    sceneName_future: 'scene_16_krs_A140_future',
    name: 'A140',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 60,
      9: 70,
      10: 80,
      11: 90,
      12: 100,
      13: 110,
      14: 120,
      15: 130,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 141,
    sceneName: 'scene_16_krs_A141',
    sceneName_future: 'scene_16_krs_A141_future',
    name: 'A141',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 61,
      9: 71,
      10: 81,
      11: 91,
      12: 101,
      13: 111,
      14: 121,
      15: 131,
    },
  },
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 142,
    sceneName: 'scene_16_krs_A142',
    sceneName_future: 'scene_16_krs_A142_future',
    name: 'A142',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
    },
  },
]

export const BUILDING_A_DATA = {
  8: FLOOR_8_DATA,
  9: FLOOR_9_DATA,
  10: FLOOR_10_DATA,
  11: FLOOR_11_DATA,
  12: FLOOR_12_DATA,
  13: FLOOR_13_DATA,
  14: FLOOR_14_DATA,
  15: FLOOR_15_DATA,
  16: FLOOR_16_DATA,
}
