import * as React from 'react'
import styled from 'styled-components'

export default function Floor11({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_x38_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.40681 136.39999"
      xmlSpace="preserve"
      enableBackground="new 0 0 210.40681 136.39999"
    >
      <g id="bg_00000063611783624334124870000004318703300637558413_">
        <g id="static_00000098220569987678949130000010745002935595109562_">
          <rect
            id="plate_00000023255644487208178420000014787462632754985117_"
            className="st0"
            width="210.40681"
            height="136.39999"
          />
        </g>
      </g>
      <g id="apartments_00000096034333027839956330000004589468417841897401_">
        <g
          onClick={() => changeView(11, 92)}
          onMouseOver={() => setElementOnHover('A092')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="_x31_42_00000123432912774061197960000002495819447022871943_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A092'
                ? 'active-apt'
                : elementOnHover === 'A092'
                ? 'on-hover'
                : ''
            }`}
            points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 91)}
          onMouseOver={() => setElementOnHover('A091')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_41_00000079465515345834653670000001082694042918379956_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A091'
                ? 'active-apt'
                : elementOnHover === 'A091'
                ? 'on-hover'
                : ''
            }`}
            points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 90)}
          onMouseOver={() => setElementOnHover('A090')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_40_00000036957671689155219370000006447961814660784518_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A090'
                ? 'active-apt'
                : elementOnHover === 'A090'
                ? 'on-hover'
                : ''
            }`}
            points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 88)}
          onMouseOver={() => setElementOnHover('A088')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_38_00000088097552535064903190000001758279888639408051_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A088'
                ? 'active-apt'
                : elementOnHover === 'A088'
                ? 'on-hover'
                : ''
            }`}
            points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 85)}
          onMouseOver={() => setElementOnHover('A085')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_35_00000142876793540593770220000002935803123545429393_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A085'
                ? 'active-apt'
                : elementOnHover === 'A085'
                ? 'on-hover'
                : ''
            }`}
            points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 84)}
          onMouseOver={() => setElementOnHover('A084')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_34_00000037674644236220018440000007453840967104022414_"
            className={`st1 ${
              sceneName === 'scene_11_krs_A084'
                ? 'active-apt'
                : elementOnHover === 'A084'
                ? 'on-hover'
                : ''
            }`}
            points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(11, 83)}
          onMouseOver={() => setElementOnHover('A083')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_33_00000072982753506183621670000003101555533529794975_"
            className={`st2 ${
              sceneName === 'scene_11_krs_A083'
                ? 'active-apt'
                : elementOnHover === 'A083'
                ? 'on-hover'
                : ''
            }`}
            points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000070831465276319842430000014372284672328632990_">
        <polygon
          id="_x31_33_00000123438299730480627450000005297491186053144718_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polyline
          id="_x31_42_00000085212641662587279080000009619480811007834558_"
          className="st3"
          points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
        />
        <polygon
          id="_x31_41_00000178166235988096239050000012781438993826347447_"
          className="st3"
          points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
        />
        <polygon
          id="_x31_40_00000048498775934756217440000014991207574194914216_"
          className="st3"
          points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
        />
        <polygon
          id="_x31_38_00000014609934378558123140000002053444884316080286_"
          className="st3"
          points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
        />
        <polygon
          id="_x31_35_00000029019432335528392460000014003878287273516951_"
          className="st3"
          points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
        />
        <polygon
          id="_x31_34_00000095307889277343394550000003431001997112758943_"
          className="st3"
          points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
        />
        <polygon
          id="_x31_33_00000116198000218604873310000007031347352567343807_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polygon
          id="runko_00000129900655225763916380000006429883546254170300_"
          className="st4"
          points="9.57856,19.58349
		10.03238,126.23048 36.35376,126.23048 36.25941,113.97742 120.30991,113.97742 120.30991,126.23048 146.17747,126.23048
		146.17747,24.12166 135.73969,24.12166 135.73969,9.59952 75.83585,9.59952 75.84483,19.58349 	"
        />
        <line
          className="st5"
          x1="10.03238"
          y1="126.23048"
          x2="64.5668"
          y2="126.23048"
        />
        <line
          className="st3"
          x1="76.81722"
          y1="54.98122"
          x2="113.25552"
          y2="54.98122"
        />
        <line
          className="st5"
          x1="95.34998"
          y1="54.98122"
          x2="95.34998"
          y2="9.59952"
        />
        <line
          className="st3"
          x1="113.95647"
          y1="63.14992"
          x2="146.17747"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="49.36169"
          y1="63.00614"
          x2="57.77303"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="64.5668"
          y1="63.14992"
          x2="77.87802"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="95.34998"
          y1="63.14992"
          x2="104.08932"
          y2="63.14992"
        />
      </g>
      <g id="apartment_nr_00000062174990735014294080000018217537465901849264_">
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A083'
                ? 'white-text'
                : elementOnHover === 'A083'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 83)}
            onMouseOver={() => setElementOnHover('A083')}
            onMouseLeave={() => setElementOnHover('')}
            d="M18.25541,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H21.0181l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H18.25541z M19.85795,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H19.85795z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A083'
                ? 'white-text'
                : elementOnHover === 'A083'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 83)}
            onMouseOver={() => setElementOnHover('A083')}
            onMouseLeave={() => setElementOnHover('')}
            d="M23.03959,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S23.90287,37.16943,23.03959,37.16943z M22.21049,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S22.21049,34.11865,22.21049,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A083'
                ? 'white-text'
                : elementOnHover === 'A083'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 83)}
            onMouseOver={() => setElementOnHover('A083')}
            onMouseLeave={() => setElementOnHover('')}
            d="M26.03812,37.16943c-0.79199,0-1.18799-0.3623-1.18799-1.08789c0-0.52344,0.23779-0.83594,0.71387-0.9375
			v-0.0127c-0.42334-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29248-0.69922
			c0.20313-0.18164,0.479-0.27246,0.82813-0.27246c0.32764,0,0.59277,0.08398,0.7959,0.25195
			c0.2085,0.17578,0.31299,0.41113,0.31299,0.70801c0,0.28906-0.0752,0.52051-0.2251,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41553,0.24512v0.0127c0.17334,0.0332,0.32471,0.11426,0.45361,0.24316
			c0.17334,0.17578,0.26025,0.41309,0.26025,0.71191C27.23197,36.81299,26.83402,37.16943,26.03812,37.16943z M25.31596,36.05225
			c0,0.51465,0.24658,0.77148,0.73975,0.77148c0.47363,0,0.71045-0.25488,0.71045-0.7666c0-0.49805-0.24268-0.74805-0.72803-0.74805
			c-0.2251,0-0.40186,0.06543-0.52979,0.19629C25.37992,35.63525,25.31596,35.81787,25.31596,36.05225z M25.39506,34.28076
			c0,0.45508,0.22412,0.68262,0.67236,0.68262c0.19385,0,0.34668-0.05957,0.45801-0.17969
			c0.11182-0.11914,0.16748-0.28516,0.16748-0.49707c0-0.47266-0.21826-0.70898-0.65479-0.70898
			c-0.20166,0-0.35938,0.06348-0.47266,0.19141C25.4517,33.896,25.39506,34.06689,25.39506,34.28076z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A083'
                ? 'white-text'
                : elementOnHover === 'A083'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 83)}
            onMouseOver={() => setElementOnHover('A083')}
            onMouseLeave={() => setElementOnHover('')}
            d="M28.35697,34.07275h-0.57129c0-0.20703,0.08545-0.38965,0.25635-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33154,0,0.59814,0.08691,0.79932,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61475-0.17773
			c0.11572-0.11133,0.17334-0.2832,0.17334-0.5166c0-0.44922-0.19385-0.67383-0.58154-0.67383
			c-0.23096,0-0.40137,0.06055-0.51074,0.18066C28.40092,33.84521,28.36088,33.95068,28.35697,34.07275z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A084'
                ? 'white-text'
                : elementOnHover === 'A084'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 84)}
            onMouseOver={() => setElementOnHover('A084')}
            onMouseLeave={() => setElementOnHover('')}
            d="M42.39408,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734H43.2642
			l-0.40137,1.27734H42.39408z M43.99662,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H43.99662z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A084'
                ? 'white-text'
                : elementOnHover === 'A084'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 84)}
            onMouseOver={() => setElementOnHover('A084')}
            onMouseLeave={() => setElementOnHover('')}
            d="M47.17826,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S48.04154,37.16943,47.17826,37.16943z M46.34916,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S46.34916,34.11865,46.34916,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A084'
                ? 'white-text'
                : elementOnHover === 'A084'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 84)}
            onMouseOver={() => setElementOnHover('A084')}
            onMouseLeave={() => setElementOnHover('')}
            d="M50.1768,37.16943c-0.79199,0-1.18799-0.3623-1.18799-1.08789c0-0.52344,0.23779-0.83594,0.71387-0.9375
			v-0.0127c-0.42334-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29248-0.69922
			c0.20313-0.18164,0.479-0.27246,0.82813-0.27246c0.32764,0,0.59277,0.08398,0.7959,0.25195
			c0.2085,0.17578,0.31299,0.41113,0.31299,0.70801c0,0.28906-0.0752,0.52051-0.2251,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41553,0.24512v0.0127c0.17334,0.0332,0.32471,0.11426,0.45361,0.24316
			c0.17334,0.17578,0.26025,0.41309,0.26025,0.71191C51.37064,36.81299,50.97269,37.16943,50.1768,37.16943z M49.45463,36.05225
			c0,0.51465,0.24658,0.77148,0.73975,0.77148c0.47363,0,0.71045-0.25488,0.71045-0.7666c0-0.49805-0.24268-0.74805-0.72803-0.74805
			c-0.2251,0-0.40186,0.06543-0.52979,0.19629C49.51859,35.63525,49.45463,35.81787,49.45463,36.05225z M49.53373,34.28076
			c0,0.45508,0.22412,0.68262,0.67236,0.68262c0.19385,0,0.34668-0.05957,0.45801-0.17969
			c0.11182-0.11914,0.16748-0.28516,0.16748-0.49707c0-0.47266-0.21826-0.70898-0.65479-0.70898
			c-0.20166,0-0.35938,0.06348-0.47266,0.19141C49.59037,33.896,49.53373,34.06689,49.53373,34.28076z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A084'
                ? 'white-text'
                : elementOnHover === 'A084'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 84)}
            onMouseOver={() => setElementOnHover('A084')}
            onMouseLeave={() => setElementOnHover('')}
            d="M53.47709,37.10205v-1.06934H51.9185v-0.36328l1.5293-2.37012h0.48047v2.32617h0.65918v0.40723h-0.65918
			v1.06934H53.47709z M53.46488,33.8208l-1.13916,1.80469h1.15137V33.8208H53.46488z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A085'
                ? 'white-text'
                : elementOnHover === 'A085'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 85)}
            onMouseOver={() => setElementOnHover('A085')}
            onMouseLeave={() => setElementOnHover('')}
            d="M60.939,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H63.7017l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H60.939z M62.54154,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H62.54154z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A085'
                ? 'white-text'
                : elementOnHover === 'A085'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 85)}
            onMouseOver={() => setElementOnHover('A085')}
            onMouseLeave={() => setElementOnHover('')}
            d="M65.72318,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S66.58646,37.16943,65.72318,37.16943z M64.89408,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S64.89408,34.11865,64.89408,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A085'
                ? 'white-text'
                : elementOnHover === 'A085'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 85)}
            onMouseOver={() => setElementOnHover('A085')}
            onMouseLeave={() => setElementOnHover('')}
            d="M68.72172,37.16943c-0.79199,0-1.18799-0.3623-1.18799-1.08789c0-0.52344,0.23779-0.83594,0.71387-0.9375
			v-0.0127c-0.42334-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29248-0.69922
			c0.20313-0.18164,0.479-0.27246,0.82813-0.27246c0.32764,0,0.59277,0.08398,0.7959,0.25195
			c0.2085,0.17578,0.31299,0.41113,0.31299,0.70801c0,0.28906-0.0752,0.52051-0.2251,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41553,0.24512v0.0127c0.17334,0.0332,0.32471,0.11426,0.45361,0.24316
			c0.17334,0.17578,0.26025,0.41309,0.26025,0.71191C69.91557,36.81299,69.51762,37.16943,68.72172,37.16943z M67.99955,36.05225
			c0,0.51465,0.24658,0.77148,0.73975,0.77148c0.47363,0,0.71045-0.25488,0.71045-0.7666c0-0.49805-0.24268-0.74805-0.72803-0.74805
			c-0.2251,0-0.40186,0.06543-0.52979,0.19629C68.06351,35.63525,67.99955,35.81787,67.99955,36.05225z M68.07865,34.28076
			c0,0.45508,0.22412,0.68262,0.67236,0.68262c0.19385,0,0.34668-0.05957,0.45801-0.17969
			c0.11182-0.11914,0.16748-0.28516,0.16748-0.49707c0-0.47266-0.21826-0.70898-0.65479-0.70898
			c-0.20166,0-0.35938,0.06348-0.47266,0.19141C68.13529,33.896,68.07865,34.06689,68.07865,34.28076z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A085'
                ? 'white-text'
                : elementOnHover === 'A085'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 85)}
            onMouseOver={() => setElementOnHover('A085')}
            onMouseLeave={() => setElementOnHover('')}
            d="M71.27201,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H71.27201z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M80.01127,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H80.01127z M81.61381,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H81.61381z"
          />
          <path
            className="st6"
            d="M84.79545,37.17578c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S85.65873,37.17578,84.79545,37.17578z M83.96635,35.20703
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S83.96635,34.125,83.96635,35.20703z"
          />
          <path
            className="st6"
            d="M87.79398,37.17578c-0.79199,0-1.18799-0.3623-1.18799-1.08789c0-0.52344,0.23779-0.83594,0.71387-0.9375
			v-0.0127c-0.42334-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29248-0.69922
			c0.20313-0.18164,0.479-0.27246,0.82813-0.27246c0.32764,0,0.59277,0.08398,0.7959,0.25195
			c0.2085,0.17578,0.31299,0.41113,0.31299,0.70801c0,0.28906-0.0752,0.52051-0.2251,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41553,0.24512v0.0127c0.17334,0.0332,0.32471,0.11426,0.45361,0.24316
			c0.17334,0.17578,0.26025,0.41309,0.26025,0.71191C88.98783,36.81934,88.58988,37.17578,87.79398,37.17578z M87.07182,36.05859
			c0,0.51465,0.24658,0.77148,0.73975,0.77148c0.47363,0,0.71045-0.25488,0.71045-0.7666c0-0.49805-0.24268-0.74805-0.72803-0.74805
			c-0.2251,0-0.40186,0.06543-0.52979,0.19629C87.13578,35.6416,87.07182,35.82422,87.07182,36.05859z M87.15092,34.28711
			c0,0.45508,0.22412,0.68262,0.67236,0.68262c0.19385,0,0.34668-0.05957,0.45801-0.17969
			c0.11182-0.11914,0.16748-0.28516,0.16748-0.49707c0-0.47266-0.21826-0.70898-0.65479-0.70898
			c-0.20166,0-0.35938,0.06348-0.47266,0.19141C87.20756,33.90234,87.15092,34.07324,87.15092,34.28711z"
          />
          <path
            className="st6"
            d="M90.14213,34.98242v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10791,0.64258-0.32373,0.85449s-0.49463,0.31836-0.83643,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C90.19291,34.29492,90.14213,34.60254,90.14213,34.98242z M90.21244,35.99512c0,0.24805,0.06445,0.44727,0.19385,0.59766
			c0.13477,0.1582,0.31885,0.2373,0.55176,0.2373c0.46191,0,0.69287-0.27637,0.69287-0.8291
			c0-0.56445-0.23486-0.84668-0.70459-0.84668c-0.22705,0-0.40625,0.07715-0.53711,0.2334
			C90.27787,35.54199,90.21244,35.74512,90.21244,35.99512z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M98.80326,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H98.80326z M100.4058,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H100.4058z"
          />
          <path
            className="st6"
            d="M103.58744,37.17578c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S104.45072,37.17578,103.58744,37.17578z M102.75834,35.20703
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S102.75834,34.125,102.75834,35.20703z"
          />
          <path
            className="st6"
            d="M106.58598,37.17578c-0.79199,0-1.18799-0.3623-1.18799-1.08789c0-0.52344,0.23779-0.83594,0.71387-0.9375
			v-0.0127c-0.42334-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29248-0.69922
			c0.20313-0.18164,0.479-0.27246,0.82813-0.27246c0.32764,0,0.59277,0.08398,0.7959,0.25195
			c0.2085,0.17578,0.31299,0.41113,0.31299,0.70801c0,0.28906-0.0752,0.52051-0.2251,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41553,0.24512v0.0127c0.17334,0.0332,0.32471,0.11426,0.45361,0.24316
			c0.17334,0.17578,0.26025,0.41309,0.26025,0.71191C107.77982,36.81934,107.38187,37.17578,106.58598,37.17578z
			 M105.86381,36.05859c0,0.51465,0.24658,0.77148,0.73975,0.77148c0.47363,0,0.71045-0.25488,0.71045-0.7666
			c0-0.49805-0.24268-0.74805-0.72803-0.74805c-0.2251,0-0.40186,0.06543-0.52979,0.19629
			C105.92777,35.6416,105.86381,35.82422,105.86381,36.05859z M105.94291,34.28711c0,0.45508,0.22412,0.68262,0.67236,0.68262
			c0.19385,0,0.34668-0.05957,0.45801-0.17969c0.11182-0.11914,0.16748-0.28516,0.16748-0.49707
			c0-0.47266-0.21826-0.70898-0.65479-0.70898c-0.20166,0-0.35938,0.06348-0.47266,0.19141
			C105.99955,33.90234,105.94291,34.07324,105.94291,34.28711z"
          />
          <path
            className="st6"
            d="M108.77006,37.1084l1.45313-3.39551h-1.74609v-0.40723h2.23828v0.38379l-1.44727,3.41895H108.77006z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A088'
                ? 'white-text'
                : elementOnHover === 'A088'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 88)}
            onMouseOver={() => setElementOnHover('A088')}
            onMouseLeave={() => setElementOnHover('')}
            d="M123.42191,37.23438l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,33.51953l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A088'
                ? 'white-text'
                : elementOnHover === 'A088'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 88)}
            onMouseOver={() => setElementOnHover('A088')}
            onMouseLeave={() => setElementOnHover('')}
            d="M128.2061,37.30176c-0.86329,0-1.29493-0.65625-1.29493-1.96875s0.43164-1.96875,1.29493-1.96875
			c0.86328,0,1.29492,0.65625,1.29492,1.96875S129.06938,37.30176,128.2061,37.30176z M127.37699,35.33301
			c0,1.08203,0.27637,1.62305,0.82911,1.62305c0.55273,0,0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			C127.65336,33.70996,127.37699,34.25098,127.37699,35.33301z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A088'
                ? 'white-text'
                : elementOnHover === 'A088'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 88)}
            onMouseOver={() => setElementOnHover('A088')}
            onMouseLeave={() => setElementOnHover('')}
            d="M131.20512,37.30176c-0.79199,0-1.18848-0.3623-1.18848-1.08789c0-0.52344,0.23828-0.83594,0.71387-0.9375
			v-0.0127c-0.42285-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29297-0.69922
			c0.20313-0.18164,0.47852-0.27246,0.82813-0.27246c0.32715,0,0.59277,0.08398,0.7959,0.25195
			c0.20801,0.17578,0.3125,0.41113,0.3125,0.70801c0,0.28906-0.0752,0.52051-0.22461,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41602,0.24512v0.0127c0.17383,0.0332,0.3252,0.11426,0.4541,0.24316
			c0.17285,0.17578,0.25977,0.41309,0.25977,0.71191C132.39848,36.94531,132.00102,37.30176,131.20512,37.30176z
			 M130.48247,36.18457c0,0.51465,0.24707,0.77148,0.74023,0.77148c0.47363,0,0.70996-0.25488,0.70996-0.7666
			c0-0.49805-0.24219-0.74805-0.72754-0.74805c-0.22559,0-0.40234,0.06543-0.53027,0.19629
			C130.54692,35.76758,130.48247,35.9502,130.48247,36.18457z M130.56157,34.41309c0,0.45508,0.22461,0.68262,0.67285,0.68262
			c0.19336,0,0.34668-0.05957,0.45801-0.17969c0.11133-0.11914,0.16699-0.28516,0.16699-0.49707
			c0-0.47266-0.21777-0.70898-0.6543-0.70898c-0.20215,0-0.35938,0.06348-0.47266,0.19141
			C130.61821,34.02832,130.56157,34.19922,130.56157,34.41309z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A088'
                ? 'white-text'
                : elementOnHover === 'A088'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 88)}
            onMouseOver={() => setElementOnHover('A088')}
            onMouseLeave={() => setElementOnHover('')}
            d="M134.20512,37.30176c-0.79199,0-1.18848-0.3623-1.18848-1.08789c0-0.52344,0.23828-0.83594,0.71387-0.9375
			v-0.0127c-0.42285-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29297-0.69922
			c0.20313-0.18164,0.47852-0.27246,0.82813-0.27246c0.32715,0,0.59277,0.08398,0.7959,0.25195
			c0.20801,0.17578,0.3125,0.41113,0.3125,0.70801c0,0.28906-0.0752,0.52051-0.22461,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41602,0.24512v0.0127c0.17383,0.0332,0.3252,0.11426,0.4541,0.24316
			c0.17285,0.17578,0.25977,0.41309,0.25977,0.71191C135.39848,36.94531,135.00102,37.30176,134.20512,37.30176z
			 M133.48247,36.18457c0,0.51465,0.24707,0.77148,0.74023,0.77148c0.47363,0,0.70996-0.25488,0.70996-0.7666
			c0-0.49805-0.24219-0.74805-0.72754-0.74805c-0.22559,0-0.40234,0.06543-0.53027,0.19629
			C133.54692,35.76758,133.48247,35.9502,133.48247,36.18457z M133.56157,34.41309c0,0.45508,0.22461,0.68262,0.67285,0.68262
			c0.19336,0,0.34668-0.05957,0.45801-0.17969c0.11133-0.11914,0.16699-0.28516,0.16699-0.49707
			c0-0.47266-0.21777-0.70898-0.6543-0.70898c-0.20215,0-0.35938,0.06348-0.47266,0.19141
			C133.61821,34.02832,133.56157,34.19922,133.56157,34.41309z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M123.42191,75.45313l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,71.73828l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className="st6"
            d="M128.2061,75.52051c-0.86329,0-1.29493-0.65625-1.29493-1.96875s0.43164-1.96875,1.29493-1.96875
			c0.86328,0,1.29492,0.65625,1.29492,1.96875S129.06938,75.52051,128.2061,75.52051z M127.37699,73.55176
			c0,1.08203,0.27637,1.62305,0.82911,1.62305c0.55273,0,0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			C127.65336,71.92871,127.37699,72.46973,127.37699,73.55176z"
          />
          <path
            className="st6"
            d="M131.20512,75.52051c-0.79199,0-1.18848-0.3623-1.18848-1.08789c0-0.52344,0.23828-0.83594,0.71387-0.9375
			v-0.0127c-0.42285-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29297-0.69922
			c0.20313-0.18164,0.47852-0.27246,0.82813-0.27246c0.32715,0,0.59277,0.08398,0.7959,0.25195
			c0.20801,0.17578,0.3125,0.41113,0.3125,0.70801c0,0.28906-0.0752,0.52051-0.22461,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41602,0.24512v0.0127c0.17383,0.0332,0.3252,0.11426,0.4541,0.24316
			c0.17285,0.17578,0.25977,0.41309,0.25977,0.71191C132.39848,75.16406,132.00102,75.52051,131.20512,75.52051z
			 M130.48247,74.40332c0,0.51465,0.24707,0.77148,0.74023,0.77148c0.47363,0,0.70996-0.25488,0.70996-0.7666
			c0-0.49805-0.24219-0.74805-0.72754-0.74805c-0.22559,0-0.40234,0.06543-0.53027,0.19629
			C130.54692,73.98633,130.48247,74.16895,130.48247,74.40332z M130.56157,72.63184c0,0.45508,0.22461,0.68262,0.67285,0.68262
			c0.19336,0,0.34668-0.05957,0.45801-0.17969c0.11133-0.11914,0.16699-0.28516,0.16699-0.49707
			c0-0.47266-0.21777-0.70898-0.6543-0.70898c-0.20215,0-0.35938,0.06348-0.47266,0.19141
			C130.61821,72.24707,130.56157,72.41797,130.56157,72.63184z"
          />
          <path
            className="st6"
            d="M134.90923,73.77051v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C134.63872,75.1748,134.90923,74.70703,134.90923,73.77051z M133.4063,72.74805c0,0.56738,0.23242,0.85059,0.69629,0.85059
			c0.23047,0,0.41211-0.07715,0.54297-0.23242s0.19629-0.35938,0.19629-0.6123c0-0.24414-0.06348-0.44141-0.19043-0.59082
			c-0.13477-0.15625-0.32031-0.23438-0.55469-0.23438C133.63676,71.92871,133.4063,72.20215,133.4063,72.74805z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A090'
                ? 'white-text'
                : elementOnHover === 'A090'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 90)}
            onMouseOver={() => setElementOnHover('A090')}
            onMouseLeave={() => setElementOnHover('')}
            d="M114.2017,101.2334l1.3208-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H114.2017z M115.80375,97.51855l-0.60645,2.04785h1.22461l-0.60645-2.04785H115.80375z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A090'
                ? 'white-text'
                : elementOnHover === 'A090'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 90)}
            onMouseOver={() => setElementOnHover('A090')}
            onMouseLeave={() => setElementOnHover('')}
            d="M118.98539,101.30078c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S119.84867,101.30078,118.98539,101.30078z M118.15629,99.33203
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S118.15629,98.25,118.15629,99.33203z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A090'
                ? 'white-text'
                : elementOnHover === 'A090'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 90)}
            onMouseOver={() => setElementOnHover('A090')}
            onMouseLeave={() => setElementOnHover('')}
            d="M122.68851,99.55078v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C122.41801,100.95508,122.68851,100.4873,122.68851,99.55078z M121.18559,98.52832c0,0.56738,0.23242,0.85059,0.69629,0.85059
			c0.23047,0,0.41211-0.07715,0.54297-0.23242s0.19629-0.35938,0.19629-0.6123c0-0.24414-0.06348-0.44141-0.19043-0.59082
			c-0.13477-0.15625-0.32031-0.23438-0.55469-0.23438C121.41605,97.70898,121.18559,97.98242,121.18559,98.52832z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A090'
                ? 'white-text'
                : elementOnHover === 'A090'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 90)}
            onMouseOver={() => setElementOnHover('A090')}
            onMouseLeave={() => setElementOnHover('')}
            d="M124.98539,101.30078c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S125.84867,101.30078,124.98539,101.30078z M124.15629,99.33203
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S124.15629,98.25,124.15629,99.33203z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A091'
                ? 'white-text'
                : elementOnHover === 'A091'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 91)}
            onMouseOver={() => setElementOnHover('A091')}
            onMouseLeave={() => setElementOnHover('')}
            d="M74.12113,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H74.12113z M75.72367,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H75.72367z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A091'
                ? 'white-text'
                : elementOnHover === 'A091'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 91)}
            onMouseOver={() => setElementOnHover('A091')}
            onMouseLeave={() => setElementOnHover('')}
            d="M78.90531,101.34961c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S79.76859,101.34961,78.90531,101.34961z M78.07621,99.38086
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S78.07621,98.29883,78.07621,99.38086z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A091'
                ? 'white-text'
                : elementOnHover === 'A091'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 91)}
            onMouseOver={() => setElementOnHover('A091')}
            onMouseLeave={() => setElementOnHover('')}
            d="M82.60844,99.59961v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C82.33793,101.00391,82.60844,100.53613,82.60844,99.59961z M81.10551,98.57715c0,0.56738,0.23193,0.85059,0.6958,0.85059
			c0.23096,0,0.41211-0.07715,0.54297-0.23242c0.13135-0.15527,0.19678-0.35938,0.19678-0.6123
			c0-0.24414-0.06348-0.44141-0.19092-0.59082c-0.13477-0.15625-0.31982-0.23438-0.55469-0.23438
			C81.33549,97.75781,81.10551,98.03125,81.10551,98.57715z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A091'
                ? 'white-text'
                : elementOnHover === 'A091'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 91)}
            onMouseOver={() => setElementOnHover('A091')}
            onMouseLeave={() => setElementOnHover('')}
            d="M84.88187,101.28223v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H84.88187z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A092'
                ? 'white-text'
                : elementOnHover === 'A092'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 92)}
            onMouseOver={() => setElementOnHover('A092')}
            onMouseLeave={() => setElementOnHover('')}
            d="M27.80033,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H27.80033z M29.40287,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H29.40287z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A092'
                ? 'white-text'
                : elementOnHover === 'A092'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 92)}
            onMouseOver={() => setElementOnHover('A092')}
            onMouseLeave={() => setElementOnHover('')}
            d="M32.58451,101.34961c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S33.44779,101.34961,32.58451,101.34961z M31.75541,99.38086
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S31.75541,98.29883,31.75541,99.38086z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A092'
                ? 'white-text'
                : elementOnHover === 'A092'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 92)}
            onMouseOver={() => setElementOnHover('A092')}
            onMouseLeave={() => setElementOnHover('')}
            d="M36.28764,99.59961v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C36.01713,101.00391,36.28764,100.53613,36.28764,99.59961z M34.78471,98.57715c0,0.56738,0.23193,0.85059,0.6958,0.85059
			c0.23096,0,0.41211-0.07715,0.54297-0.23242c0.13135-0.15527,0.19678-0.35938,0.19678-0.6123
			c0-0.24414-0.06348-0.44141-0.19092-0.59082c-0.13477-0.15625-0.31982-0.23438-0.55469-0.23438
			C35.01469,97.75781,34.78471,98.03125,34.78471,98.57715z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_11_krs_A092'
                ? 'white-text'
                : elementOnHover === 'A092'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(11, 92)}
            onMouseOver={() => setElementOnHover('A092')}
            onMouseLeave={() => setElementOnHover('')}
            d="M37.41557,101.28223v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H37.41557z"
          />
        </g>
      </g>
      <g
        id="state_00000178205300602062384000000003819864237522912165_"
        className="switch"
        onClick={toggleSceneNameFuture}
      >
        <path
          id="plate_00000018957056829247707270000014606385275113792669_"
          className="st6"
          d="M169.53586,117.0297h-0.00002
		c-5.59163,0-10.16658-4.57496-10.16658-10.16658V91.3745c0-5.59162,4.57495-10.16658,10.16658-10.16658h0.00002
		c5.59161,0,10.16658,4.57496,10.16658,10.16658v15.48862C179.70244,112.45474,175.12747,117.0297,169.53586,117.0297z"
        />
        {isSceneNameFuture ? (
          <g id="_x38__00000126319114376669666410000006167112080054235581_">
            <path
              className="st6"
              d="M184.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H184.93462z"
            />
            <path
              className="st6"
              d="M190.49321,108.73438c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S191.64458,108.73438,190.49321,108.73438z M189.38774,106.10938
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.38774,104.66602,189.38774,106.10938z"
            />
            <path
              className="st6"
              d="M192.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H192.93462z"
            />
            <path
              className="st6"
              d="M196.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H196.93462z"
            />
            <path
              className="st6"
              d="M200.90727,106.92578v-0.54688h2.43359v0.54688H200.90727z"
            />
          </g>
        ) : (
          <g id="_x38__00000180329793564453187760000012975837937188775856_">
            <path
              className="st6"
              d="M185.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H185.32036z"
            />
            <path
              className="st6"
              d="M190.87895,93.97949c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S192.03032,93.97949,190.87895,93.97949z M189.77348,91.35449
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.77348,89.91113,189.77348,91.35449z"
            />
            <path
              className="st6"
              d="M193.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H193.32036z"
            />
            <path
              className="st6"
              d="M198.8477,93.88965v-4.45703l-0.96094,0.64844v-0.60547l1.04688-0.65625h0.51172v5.07031H198.8477z"
            />
          </g>
        )}

        {isSceneNameFuture ? (
          <g
            id="down_00000082332037099320062870000000393797405916511668_"
            className="switch"
            onClick={toggleSceneNameFuture}
          >
            <path
              className="st0"
              d="M169.53584,98.9855c-4.12483,0-7.39626,3.27142-7.39626,7.39626s3.27142,7.39626,7.39626,7.39626
			c3.98262,0,7.39627-3.27142,7.39627-7.39626S173.51846,98.9855,169.53584,98.9855z"
            />
          </g>
        ) : (
          <g
            id="up_00000145749520936215792580000010993095966708365231_"
            className="switch"
            onClick={toggleSceneNameFuture}
          >
            <path
              className="st0"
              d="M169.53584,84.19297c-4.12483,0-7.39626,3.27142-7.39626,7.39626c0,4.12485,3.27142,7.39627,7.39626,7.39627
			c3.98262,0,7.39627-3.27142,7.39627-7.39627C176.93211,87.46439,173.51846,84.19297,169.53584,84.19297z"
            />
          </g>
        )}
      </g>
      <g id="view_00000044135973831976808920000002463324995868667816_">
        {apartmentNumber === 83 ? (
          <g id="A083_00000060716208092015249010000005783802946993985937_">
            <linearGradient
              id="A083cone_00000121998758811142123430000011717143878374000566_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="184.40488"
              x2="187.5202"
              y2="139.49487"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A083cone"
              style={{
                fill: 'url(#A083cone_00000121998758811142123430000011717143878374000566_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M23.19704,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L23.19704,17.25664z"
            />
            <circle
              id="A083"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 84 ? (
          <g id="A084_00000074441353533578099340000009304030330960095360_">
            <linearGradient
              id="A084cone_00000060016958812238496670000000947411645376564390_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="206.65987"
              x2="187.5202"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A084cone"
              style={{
                fill: 'url(#A084cone_00000060016958812238496670000000947411645376564390_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M45.45204,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,17.25664z"
            />
            <circle
              id="A084"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 85 ? (
          <g id="A085_00000050642444122441213500000012366361951505628821_">
            <linearGradient
              id="A085cone_00000133494546243828285250000002949662178178518202_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="224.35446"
              x2="187.5202"
              y2="179.44446"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A085cone"
              style={{
                fill: 'url(#A085cone_00000133494546243828285250000002949662178178518202_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M63.14662,17.25664
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L63.14662,17.25664z"
            />
            <circle
              id="A085"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 88 ? (
          <g id="A088_00000025438660594885234060000008195951844139506057_">
            <linearGradient
              id="A088cone_00000173159111838721303490000012968474594066567567_"
              gradientUnits="userSpaceOnUse"
              x1="189.84561"
              y1="297.95212"
              x2="189.84561"
              y2="253.0421"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A088cone"
              style={{
                fill: 'url(#A088cone_00000173159111838721303490000012968474594066567567_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M136.74428,19.58205
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L136.74428,19.58205z"
            />
            <circle
              id="A088"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 90 ? (
          <g id="A090_00000124155475215707787690000012175135309944064936_">
            <linearGradient
              id="A090cone_00000014609843945259060300000007966183918865768596_"
              gradientUnits="userSpaceOnUse"
              x1="293.59818"
              y1="261.71448"
              x2="293.59818"
              y2="216.80447"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A090cone"
              style={{
                fill: 'url(#A090cone_00000014609843945259060300000007966183918865768596_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M100.50665,123.33463
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L100.50665,123.33463z"
            />
            <circle
              id="A090"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 91 ? (
          <g id="A091_00000011005067100520896640000015338039039012810931_">
            <linearGradient
              id="A091cone_00000088134109956843953370000010748833223107688852_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="235.11095"
              x2="294.20319"
              y2="190.20094"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A091cone"
              style={{
                fill: 'url(#A091cone_00000088134109956843953370000010748833223107688852_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M73.90311,123.93962
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L73.90311,123.93962z"
            />
            <circle
              id="A091"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 92 ? (
          <g id="A092_00000093174133259824822500000009362297122992321163_">
            <linearGradient
              id="A092cone_00000063612255752703545090000017112687839146089391_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="206.65987"
              x2="294.20319"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A092cone"
              style={{
                fill: 'url(#A092cone_00000063612255752703545090000017112687839146089391_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="M45.45204,123.93962
			c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,123.93962z"
            />
            <circle
              id="A092"
              className="st9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_00000003804336407976517980000005547119181700589470_">
        <path
          id="plate_00000148660464451223502780000016174342398842744486_"
          className="st6"
          d="M169.53586,73.90963h-0.00002
		c-5.59163,0-10.16658-4.57497-10.16658-10.16658V29.3607c0-5.59162,4.57495-10.16658,10.16658-10.16658h0.00002
		c5.59161,0,10.16658,4.57496,10.16658,10.16658v34.38235C179.70244,69.33466,175.12747,73.90963,169.53586,73.90963z"
        />
        <g id="_x38__00000015315785642852945170000014428756577213941128_">
          <path
            className="st7"
            d="M167.5049286,48.980957v-4.4570313l-0.9609375,0.6484375v-0.6054688l1.046875-0.65625h0.5117188v5.0703125
			H167.5049286z"
          />
          <path
            className="st7"
            d="M171.5049286,48.980957v-4.4570313l-0.9609375,0.6484375v-0.6054688l1.046875-0.65625h0.5117188v5.0703125
			H171.5049286z"
          />
        </g>
        <g
          id="down_00000072281229267824440250000006620727209343054526_"
          onClick={() => changeFloor('down')}
        >
          <path
            className="st0"
            d="M169.53584,55.39469c-4.12483,0-7.39626,3.27142-7.39626,7.39627s3.27142,7.39626,7.39626,7.39626
			c3.98262,0,7.39627-3.27142,7.39627-7.39626S173.51846,55.39469,169.53584,55.39469z"
          />
          <path
            className="st6"
            d="M174.37186,61.3686l0.42671,0.56894l-4.83601,3.27142c-0.14224,0.14223-0.42671,0.14223-0.56892,0
			l-4.83601-3.27142l0.42671-0.56894l4.55154,2.98696c0.14224,0.14223,0.28447,0.14223,0.42671,0L174.37186,61.3686z"
          />
        </g>
        <g
          id="up_00000157272274662373474900000004197136333832917182_"
          onClick={() => changeFloor('up')}
        >
          <path
            className="st0"
            d="M169.53584,22.57308c-4.12483,0-7.39626,3.27143-7.39626,7.39626s3.27142,7.39627,7.39626,7.39627
			c3.98262,0,7.39627-3.27142,7.39627-7.39627S173.51846,22.57308,169.53584,22.57308z"
          />
          <path
            className="st6"
            d="M174.37186,31.3917l0.42671-0.56894l-4.83601-3.27142c-0.14224-0.14224-0.42671-0.14224-0.56892,0
			l-4.83601,3.27142l0.42671,0.56894l4.55154-2.98695c0.14224-0.14224,0.28447-0.14224,0.42671,0L174.37186,31.3917z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  83: [27.79705, 14.48664],
  84: [50.05205, 14.48664],
  85: [67.74663, 14.48664],
  88: [141.34428, 16.81205],
  90: [105.10666, 120.56463],
  91: [78.50312, 121.16962],
  92: [50.05205, 121.16962],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e7e7e7;
  }
  .st2 {
    fill: #e7e7e7;
    stroke: #3f3f3c;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #d5d4cf;
    stroke: #3f3f3c;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    fill: #ffffff;
  }
  .st8 {
    fill: url(#A083cone_00000118359231915689065290000016567250870733837454_);
  }
  .st9 {
    fill: #c65805;
  }
  .st10 {
    fill: url(#A084cone_00000153700780161822415540000018307213869377236908_);
  }
  .st11 {
    fill: url(#A085cone_00000046325255982935272770000000431120710463339656_);
  }
  .st12 {
    fill: url(#A088cone_00000030450147924026671900000004921206650459231676_);
  }
  .st13 {
    fill: url(#A090cone_00000163058143935853710660000008591566685188097707_);
  }
  .st14 {
    fill: url(#A091cone_00000068664437919317359410000015792710806791508924_);
  }
  .st15 {
    fill: url(#A092cone_00000079477219776995662660000017702171114408886664_);
  }

  #_x31_33_00000072982753506183621670000003101555533529794975_,
  #_x31_34_00000037674644236220018440000007453840967104022414_,
  #_x31_35_00000142876793540593770220000002935803123545429393_,
  #_x31_38_00000088097552535064903190000001758279888639408051_,
  #_x31_40_00000036957671689155219370000006447961814660784518_,
  #_x31_41_00000079465515345834653670000001082694042918379956_,
  #_x31_42_00000123432912774061197960000002495819447022871943_ {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: #c65805;
    }
  }

  #up_00000157272274662373474900000004197136333832917182_:hover,
  #down_00000072281229267824440250000006620727209343054526_:hover,
  #down_00000082332037099320062870000000393797405916511668_:hover,
  #up_00000145749520936215792580000010993095966708365231_:hover {
    cursor: pointer;

    .st0 {
      fill: #c65805;
    }

    .st6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: #c65805;
  }

  .on-hover {
    fill: #c65805;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .larger-text {
    transform: scale(1.3);
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  .sold {
    fill: #af6967;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
