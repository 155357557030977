export const initialApartments = {
  8: {
    floor: 8,
    building: 'A',
    apartmentNumber: 62,
    sceneName: 'scene_08_krs_A062',
    sceneName_future: 'scene_08_krs_A062_future',
    name: 'A062',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  9: {
    floor: 9,
    building: 'A',
    apartmentNumber: 72,
    sceneName: 'scene_09_krs_A072',
    sceneName_future: 'scene_09_krs_A072_future',
    name: 'A072',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  10: {
    floor: 10,
    building: 'A',
    apartmentNumber: 82,
    sceneName: 'scene_10_krs_A082',
    sceneName_future: 'scene_10_krs_A082_future',
    name: 'A082',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  11: {
    floor: 11,
    building: 'A',
    apartmentNumber: 92,
    sceneName: 'scene_11_krs_A092',
    sceneName_future: 'scene_11_krs_A092_future',
    name: 'A092',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  12: {
    floor: 12,
    building: 'A',
    apartmentNumber: 102,
    sceneName: 'scene_12_krs_A102',
    sceneName_future: 'scene_12_krs_A102_future',
    name: 'A0102',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      13: 112,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  13: {
    floor: 13,
    building: 'A',
    apartmentNumber: 112,
    sceneName: 'scene_13_krs_A112',
    sceneName_future: 'scene_13_krs_A112_future',
    name: 'A112',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      14: 122,
      15: 132,
      16: 142,
    },
  },
  14: {
    floor: 14,
    building: 'A',
    apartmentNumber: 122,
    sceneName: 'scene_14_krs_A122',
    sceneName_future: 'scene_14_krs_A122_future',
    name: 'A122',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      15: 132,
      16: 142,
    },
  },
  15: {
    floor: 15,
    building: 'A',
    apartmentNumber: 132,
    sceneName: 'scene_15_krs_A132',
    sceneName_future: 'scene_15_krs_A132_future',
    name: 'A132',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      16: 142,
    },
  },
  16: {
    floor: 16,
    building: 'A',
    apartmentNumber: 142,
    sceneName: 'scene_16_krs_A142',
    sceneName_future: 'scene_16_krs_A142_future',
    name: 'A142',
    lookAt: '-160, 14.11, 120',
    face: 'south',

    relatedApartments: {
      8: 62,
      9: 72,
      10: 82,
      11: 92,
      12: 102,
      13: 112,
      14: 122,
      15: 132,
    },
  },
}
