import * as React from 'react'
import styled from 'styled-components'

export default function Floor8({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_x38_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.40681 136.39999"
      xmlSpace="preserve"
      enableBackground="new 0 0 210.40681 136.39999"
    >
      <g id="bg_00000183244255507978703250000009664975002564664214_">
        <g id="static_00000144299177481528001150000006483435837202453166_">
          <rect
            id="plate_00000072282299587723927980000011460608458660108222_"
            className="st0"
            width="210.40681"
            height="136.39999"
          />
        </g>
      </g>
      <g id="apartments_00000078034459133194142030000007039977702565630121_">
        <g
          onClick={() => changeView(8, 62)}
          onMouseOver={() => setElementOnHover('A062')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="_x31_42_00000027570608014911752230000012566462189212508034_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A062'
                ? 'active-apt'
                : elementOnHover === 'A062'
                ? 'on-hover'
                : ''
            }`}
            points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 61)}
          onMouseOver={() => setElementOnHover('A061')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_41_00000150072932780996024220000016767465916692874686_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A061'
                ? 'active-apt'
                : elementOnHover === 'A061'
                ? 'on-hover'
                : ''
            }`}
            points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 60)}
          onMouseOver={() => setElementOnHover('A060')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_40_00000156578858643408984320000009567644132417164167_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A060'
                ? 'active-apt'
                : elementOnHover === 'A060'
                ? 'on-hover'
                : ''
            }`}
            points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 58)}
          onMouseOver={() => setElementOnHover('A058')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_38_00000169545317567110637140000017938115892260140160_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A058'
                ? 'active-apt'
                : elementOnHover === 'A058'
                ? 'on-hover'
                : ''
            }`}
            points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 55)}
          onMouseOver={() => setElementOnHover('A055')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_35_00000148645023103758215740000008878971228624982407_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A055'
                ? 'active-apt'
                : elementOnHover === 'A055'
                ? 'on-hover'
                : ''
            }`}
            points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 54)}
          onMouseOver={() => setElementOnHover('A054')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_34_00000063608411035549729760000001245144089655726489_"
            className={`st1 ${
              sceneName === 'scene_08_krs_A054'
                ? 'active-apt'
                : elementOnHover === 'A054'
                ? 'on-hover'
                : ''
            }`}
            points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 53)}
          onMouseOver={() => setElementOnHover('A053')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="_x31_33_00000164511514952297587520000009917380297816848775_"
            className={`st2 ${
              sceneName === 'scene_08_krs_A053'
                ? 'active-apt'
                : elementOnHover === 'A053'
                ? 'on-hover'
                : ''
            }`}
            points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000120561236630925877440000003648792889203429774_">
        <polygon
          id="_x31_33_00000121993321888873900470000000691704256235282822_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polyline
          id="_x31_42_00000134235465938810645890000007865181461434864314_"
          className="st3"
          points="10.03238,126.23048
		9.84785,82.8659 57.77303,82.8659 57.77303,63.14992 64.5668,63.14992 64.5668,67.25275 68.61072,67.25275 68.61072,73.49801
		71.36058,73.49801 71.36058,82.8659 64.5668,82.8659 64.5668,126.23048 64.5668,126.23048 	"
        />
        <polygon
          id="_x31_41_00000091730290559778919950000015182669451961681834_"
          className="st3"
          points="64.5668,126.23048
		64.5668,82.8659 71.36058,82.8659 71.36058,73.49801 77.87802,73.49801 77.87802,63.14992 95.34998,63.14992 95.34998,114.43124
		92.38893,114.43916 92.38893,126.23048 	"
        />
        <polygon
          id="_x31_40_00000072970641824123691230000017909177103178055303_"
          className="st3"
          points="92.38893,126.23048
		92.38893,114.43916 95.34998,114.43916 95.34998,77.29463 104.08932,77.29463 104.08932,63.14992 113.95647,63.14992
		113.95647,82.21024 146.17747,82.21024 146.17747,126.23048 	"
        />
        <polygon
          id="_x31_38_00000175321281910925312060000012285902686982442624_"
          className="st3"
          points="113.25552,9.59952
		113.37334,54.98221 123.58416,54.98221 123.48663,48.04163 146.17747,48.04163 146.17747,24.12166 146.17747,9.59952
		136.17682,9.59952 	"
        />
        <polygon
          id="_x31_35_00000149374342556713138870000012845059429693485996_"
          className="st3"
          points="76.49174,9.59952
		76.81722,54.98122 68.01822,54.98122 68.01822,51.8045 58.2583,51.8045 58.2583,19.58349 59.09726,19.58349 59.09726,9.59952 	"
        />
        <polygon
          id="_x31_34_00000000911372201283874040000004875349293277915831_"
          className="st3"
          points="42.7072,9.59952 42.7072,19.58349
		39.6563,19.58349 39.6563,52.00669 49.36169,52.00669 49.36169,54.98122 58.2583,54.98122 58.2583,19.58349 59.06708,19.58349
		59.06708,9.59952 	"
        />
        <polygon
          id="_x31_33_00000176723210440570729120000012525648182171081880_"
          className="st3"
          points="39.6563,19.58349
		39.6563,52.00669 49.36169,52.00669 49.36169,54.41526 49.36169,63.00614 41.57266,63.00614 41.57266,82.8659 9.84785,82.8659
		9.84785,9.59952 42.7072,9.59952 42.7072,19.58349 	"
        />
        <polygon
          id="runko_00000155852034066138717680000000688385954279853449_"
          className="st4"
          points="9.57856,19.58349
		10.03238,126.23048 36.35376,126.23048 36.25941,113.97742 120.30991,113.97742 120.30991,126.23048 146.17747,126.23048
		146.17747,24.12166 135.73969,24.12166 135.73969,9.59952 75.83585,9.59952 75.84483,19.58349 	"
        />
        <line
          className="st5"
          x1="10.03238"
          y1="126.23048"
          x2="64.5668"
          y2="126.23048"
        />
        <line
          className="st3"
          x1="76.81722"
          y1="54.98122"
          x2="113.25552"
          y2="54.98122"
        />
        <line
          className="st5"
          x1="95.34998"
          y1="54.98122"
          x2="95.34998"
          y2="9.59952"
        />
        <line
          className="st3"
          x1="113.95647"
          y1="63.14992"
          x2="146.17747"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="49.36169"
          y1="63.00614"
          x2="57.77303"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="64.5668"
          y1="63.14992"
          x2="77.87802"
          y2="63.14992"
        />
        <line
          className="st3"
          x1="95.34998"
          y1="63.14992"
          x2="104.08932"
          y2="63.14992"
        />
      </g>
      <g id="apartment_nr_00000047039670264481030410000012251940369582449546_">
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A053'
                ? 'white-text'
                : elementOnHover === 'A053'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 53)}
            onMouseOver={() => setElementOnHover('A053')}
            onMouseLeave={() => setElementOnHover('')}
            d="M18.25541,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H21.0181l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H18.25541z M19.85795,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H19.85795z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A053'
                ? 'white-text'
                : elementOnHover === 'A053'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 53)}
            onMouseOver={() => setElementOnHover('A053')}
            onMouseLeave={() => setElementOnHover('')}
            d="M23.03959,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S23.90287,37.16943,23.03959,37.16943z M22.21049,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S22.21049,34.11865,22.21049,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A053'
                ? 'white-text'
                : elementOnHover === 'A053'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 53)}
            onMouseOver={() => setElementOnHover('A053')}
            onMouseLeave={() => setElementOnHover('')}
            d="M25.58842,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H25.58842z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A053'
                ? 'white-text'
                : elementOnHover === 'A053'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 53)}
            onMouseOver={() => setElementOnHover('A053')}
            onMouseLeave={() => setElementOnHover('')}
            d="M28.35697,34.07275h-0.57129c0-0.20703,0.08545-0.38965,0.25635-0.54785
			c0.20898-0.19531,0.50293-0.29297,0.88086-0.29297c0.33154,0,0.59814,0.08691,0.79932,0.26074
			c0.20898,0.18066,0.31348,0.43164,0.31348,0.75098c0,0.25195-0.07617,0.46191-0.22852,0.62891
			c-0.12109,0.13281-0.25098,0.20898-0.38965,0.22852v0.01172c0.45508,0.10156,0.68262,0.44043,0.68262,1.01465
			c0,0.33301-0.10254,0.58984-0.30762,0.77051s-0.48438,0.27148-0.83789,0.27148c-0.39844,0-0.7002-0.09766-0.90527-0.29297
			c-0.15625-0.14844-0.24414-0.33691-0.26367-0.56543h0.52148c0.05273,0.3418,0.26074,0.5127,0.62402,0.5127
			c0.46875,0,0.70313-0.24512,0.70313-0.7373c0-0.33105-0.09766-0.55664-0.29297-0.67773
			c-0.12305-0.07813-0.30957-0.11719-0.55957-0.11719v-0.3457c0.28418,0,0.48926-0.05957,0.61475-0.17773
			c0.11572-0.11133,0.17334-0.2832,0.17334-0.5166c0-0.44922-0.19385-0.67383-0.58154-0.67383
			c-0.23096,0-0.40137,0.06055-0.51074,0.18066C28.40092,33.84521,28.36088,33.95068,28.35697,34.07275z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A054'
                ? 'white-text'
                : elementOnHover === 'A054'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 54)}
            onMouseOver={() => setElementOnHover('A054')}
            onMouseLeave={() => setElementOnHover('')}
            d="M42.39408,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734H43.2642
			l-0.40137,1.27734H42.39408z M43.99662,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H43.99662z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A054'
                ? 'white-text'
                : elementOnHover === 'A054'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 54)}
            onMouseOver={() => setElementOnHover('A054')}
            onMouseLeave={() => setElementOnHover('')}
            d="M47.17826,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S48.04154,37.16943,47.17826,37.16943z M46.34916,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S46.34916,34.11865,46.34916,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A054'
                ? 'white-text'
                : elementOnHover === 'A054'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 54)}
            onMouseOver={() => setElementOnHover('A054')}
            onMouseLeave={() => setElementOnHover('')}
            d="M49.72709,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H49.72709z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A054'
                ? 'white-text'
                : elementOnHover === 'A054'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 54)}
            onMouseOver={() => setElementOnHover('A054')}
            onMouseLeave={() => setElementOnHover('')}
            d="M53.47709,37.10205v-1.06934H51.9185v-0.36328l1.5293-2.37012h0.48047v2.32617h0.65918v0.40723h-0.65918
			v1.06934H53.47709z M53.46488,33.8208l-1.13916,1.80469h1.15137V33.8208H53.46488z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A055'
                ? 'white-text'
                : elementOnHover === 'A055'
                ? 'white-text'
                : ''
            }`}
            d="M60.939,37.10205l1.32129-4.13965h0.57129l1.33594,4.13965H63.7017l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H60.939z M62.54154,33.38721l-0.60645,2.04785h1.22461l-0.60645-2.04785H62.54154z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A055'
                ? 'white-text'
                : elementOnHover === 'A055'
                ? 'white-text'
                : ''
            }`}
            d="M65.72318,37.16943c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S66.58646,37.16943,65.72318,37.16943z M64.89408,35.20068
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S64.89408,34.11865,64.89408,35.20068z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A055'
                ? 'white-text'
                : elementOnHover === 'A055'
                ? 'white-text'
                : ''
            }`}
            d="M68.27201,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H68.27201z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A055'
                ? 'white-text'
                : elementOnHover === 'A055'
                ? 'white-text'
                : ''
            }`}
            d="M71.27201,33.70654l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H71.27201z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M80.01127,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H80.01127z M81.61381,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H81.61381z"
          />
          <path
            className="st6"
            d="M84.79545,37.17578c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S85.65873,37.17578,84.79545,37.17578z M83.96635,35.20703
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S83.96635,34.125,83.96635,35.20703z"
          />
          <path
            className="st6"
            d="M87.34428,33.71289l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H87.34428z"
          />
          <path
            className="st6"
            d="M90.14213,34.98242v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10791,0.64258-0.32373,0.85449s-0.49463,0.31836-0.83643,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C90.19291,34.29492,90.14213,34.60254,90.14213,34.98242z M90.21244,35.99512c0,0.24805,0.06445,0.44727,0.19385,0.59766
			c0.13477,0.1582,0.31885,0.2373,0.55176,0.2373c0.46191,0,0.69287-0.27637,0.69287-0.8291
			c0-0.56445-0.23486-0.84668-0.70459-0.84668c-0.22705,0-0.40625,0.07715-0.53711,0.2334
			C90.27787,35.54199,90.21244,35.74512,90.21244,35.99512z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M98.80326,37.1084l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H98.80326z M100.4058,33.39355l-0.60645,2.04785h1.22461l-0.60645-2.04785H100.4058z"
          />
          <path
            className="st6"
            d="M103.58744,37.17578c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S104.45072,37.17578,103.58744,37.17578z M102.75834,35.20703
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S102.75834,34.125,102.75834,35.20703z"
          />
          <path
            className="st6"
            d="M106.13627,33.71289l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13818,0.32813c0.11523,0.125,0.27783,0.1875,0.4873,0.1875c0.5127,0,0.76904-0.27637,0.76904-0.8291
			c0-0.29688-0.0752-0.52051-0.22607-0.6709c-0.12744-0.125-0.2876-0.1875-0.48145-0.1875
			c-0.26807,0-0.46875,0.10059-0.60205,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H106.13627z"
          />
          <path
            className="st6"
            d="M108.77006,37.1084l1.45313-3.39551h-1.74609v-0.40723h2.23828v0.38379l-1.44727,3.41895H108.77006z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A058'
                ? 'white-text'
                : elementOnHover === 'A058'
                ? 'white-text'
                : ''
            }`}
            d="M123.42191,37.23438l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,33.51953l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A058'
                ? 'white-text'
                : elementOnHover === 'A058'
                ? 'white-text'
                : ''
            }`}
            d="M128.2061,37.30176c-0.86329,0-1.29493-0.65625-1.29493-1.96875s0.43164-1.96875,1.29493-1.96875
			c0.86328,0,1.29492,0.65625,1.29492,1.96875S129.06938,37.30176,128.2061,37.30176z M127.37699,35.33301
			c0,1.08203,0.27637,1.62305,0.82911,1.62305c0.55273,0,0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			C127.65336,33.70996,127.37699,34.25098,127.37699,35.33301z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A058'
                ? 'white-text'
                : elementOnHover === 'A058'
                ? 'white-text'
                : ''
            }`}
            d="M130.75493,33.83887l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13867,0.32813c0.11523,0.125,0.27734,0.1875,0.4873,0.1875c0.5127,0,0.76855-0.27637,0.76855-0.8291
			c0-0.29688-0.0752-0.52051-0.22559-0.6709c-0.12793-0.125-0.28809-0.1875-0.48145-0.1875
			c-0.26855,0-0.46875,0.10059-0.60254,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H130.75493z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A058'
                ? 'white-text'
                : elementOnHover === 'A058'
                ? 'white-text'
                : ''
            }`}
            d="M134.20512,37.30176c-0.79199,0-1.18848-0.3623-1.18848-1.08789c0-0.52344,0.23828-0.83594,0.71387-0.9375
			v-0.0127c-0.42285-0.12793-0.63477-0.4375-0.63477-0.92773c0-0.29102,0.09766-0.52344,0.29297-0.69922
			c0.20313-0.18164,0.47852-0.27246,0.82813-0.27246c0.32715,0,0.59277,0.08398,0.7959,0.25195
			c0.20801,0.17578,0.3125,0.41113,0.3125,0.70801c0,0.28906-0.0752,0.52051-0.22461,0.69434
			c-0.11719,0.13477-0.25586,0.21582-0.41602,0.24512v0.0127c0.17383,0.0332,0.3252,0.11426,0.4541,0.24316
			c0.17285,0.17578,0.25977,0.41309,0.25977,0.71191C135.39848,36.94531,135.00102,37.30176,134.20512,37.30176z
			 M133.48247,36.18457c0,0.51465,0.24707,0.77148,0.74023,0.77148c0.47363,0,0.70996-0.25488,0.70996-0.7666
			c0-0.49805-0.24219-0.74805-0.72754-0.74805c-0.22559,0-0.40234,0.06543-0.53027,0.19629
			C133.54692,35.76758,133.48247,35.9502,133.48247,36.18457z M133.56157,34.41309c0,0.45508,0.22461,0.68262,0.67285,0.68262
			c0.19336,0,0.34668-0.05957,0.45801-0.17969c0.11133-0.11914,0.16699-0.28516,0.16699-0.49707
			c0-0.47266-0.21777-0.70898-0.6543-0.70898c-0.20215,0-0.35938,0.06348-0.47266,0.19141
			C133.61821,34.02832,133.56157,34.19922,133.56157,34.41309z"
          />
        </g>
        <g>
          <path
            className="st6"
            d="M123.42191,75.45313l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H123.42191z M125.02445,71.73828l-0.60645,2.04785h1.22461l-0.60645-2.04785H125.02445z"
          />
          <path
            className="st6"
            d="M128.2061,75.52051c-0.86329,0-1.29493-0.65625-1.29493-1.96875s0.43164-1.96875,1.29493-1.96875
			c0.86328,0,1.29492,0.65625,1.29492,1.96875S129.06938,75.52051,128.2061,75.52051z M127.37699,73.55176
			c0,1.08203,0.27637,1.62305,0.82911,1.62305c0.55273,0,0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			C127.65336,71.92871,127.37699,72.46973,127.37699,73.55176z"
          />
          <path
            className="st6"
            d="M130.75493,72.05762l-0.05273,1.27441c0.18945-0.12305,0.39941-0.18457,0.62988-0.18457
			c0.375,0,0.66309,0.11719,0.86426,0.35156c0.18359,0.21777,0.27539,0.50391,0.27539,0.86035
			c0,0.37109-0.11914,0.66016-0.35742,0.86816c-0.22266,0.19531-0.51367,0.29297-0.87305,0.29297
			c-0.41797,0-0.73047-0.11035-0.9375-0.33203c-0.15039-0.15918-0.22559-0.33496-0.22559-0.5293h0.5332
			c0,0.11914,0.0459,0.22852,0.13867,0.32813c0.11523,0.125,0.27734,0.1875,0.4873,0.1875c0.5127,0,0.76855-0.27637,0.76855-0.8291
			c0-0.29688-0.0752-0.52051-0.22559-0.6709c-0.12793-0.125-0.28809-0.1875-0.48145-0.1875
			c-0.26855,0-0.46875,0.10059-0.60254,0.30176h-0.45703l0.10254-2.13867h1.88379v0.40723H130.75493z"
          />
          <path
            className="st6"
            d="M134.90923,73.77051v-0.27734h-0.01172c-0.20117,0.30078-0.50391,0.45117-0.9082,0.45117
			c-0.34961,0-0.61621-0.11719-0.7998-0.35156c-0.16602-0.21191-0.24902-0.49316-0.24902-0.84473
			c0-0.35645,0.1084-0.63965,0.3252-0.85156c0.21094-0.20898,0.48828-0.31348,0.83203-0.31348
			c0.51367,0,0.86816,0.19824,1.06348,0.59473c0.14258,0.29102,0.21387,0.74707,0.21387,1.36816
			c0,0.60938-0.08008,1.06836-0.24023,1.37695c-0.20508,0.39844-0.55469,0.59766-1.04883,0.59766
			c-0.37695,0-0.66309-0.1123-0.8584-0.33789c-0.14063-0.16309-0.21094-0.33496-0.21094-0.51758h0.53613
			c0,0.11133,0.03516,0.21484,0.10547,0.31055c0.09961,0.13281,0.24609,0.19922,0.43945,0.19922
			C134.63872,75.1748,134.90923,74.70703,134.90923,73.77051z M133.4063,72.74805c0,0.56738,0.23242,0.85059,0.69629,0.85059
			c0.23047,0,0.41211-0.07715,0.54297-0.23242s0.19629-0.35938,0.19629-0.6123c0-0.24414-0.06348-0.44141-0.19043-0.59082
			c-0.13477-0.15625-0.32031-0.23438-0.55469-0.23438C133.63676,71.92871,133.4063,72.20215,133.4063,72.74805z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A060'
                ? 'white-text'
                : elementOnHover === 'A060'
                ? 'white-text'
                : ''
            }`}
            d="M114.2017,101.2334l1.3208-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H114.2017z M115.80375,97.51855l-0.60645,2.04785h1.22461l-0.60645-2.04785H115.80375z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A060'
                ? 'white-text'
                : elementOnHover === 'A060'
                ? 'white-text'
                : ''
            }`}
            d="M118.98539,101.30078c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S119.84867,101.30078,118.98539,101.30078z M118.15629,99.33203
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S118.15629,98.25,118.15629,99.33203z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A060'
                ? 'white-text'
                : elementOnHover === 'A060'
                ? 'white-text'
                : ''
            }`}
            d="M121.33207,99.10742v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10742,0.64258-0.32324,0.85449s-0.49512,0.31836-0.83691,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C121.38285,98.41992,121.33207,98.72754,121.33207,99.10742z M121.40238,100.12012c0,0.24805,0.06445,0.44727,0.19434,0.59766
			c0.13477,0.1582,0.31836,0.2373,0.55176,0.2373c0.46191,0,0.69238-0.27637,0.69238-0.8291
			c0-0.56445-0.23438-0.84668-0.7041-0.84668c-0.22754,0-0.40625,0.07715-0.53711,0.2334
			C121.46781,99.66699,121.40238,99.87012,121.40238,100.12012z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A060'
                ? 'white-text'
                : elementOnHover === 'A060'
                ? 'white-text'
                : ''
            }`}
            d="M124.98539,101.30078c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S125.84867,101.30078,124.98539,101.30078z M124.15629,99.33203
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S124.15629,98.25,124.15629,99.33203z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A061'
                ? 'white-text'
                : elementOnHover === 'A061'
                ? 'white-text'
                : ''
            }`}
            d="M74.12113,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H74.12113z M75.72367,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H75.72367z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A061'
                ? 'white-text'
                : elementOnHover === 'A061'
                ? 'white-text'
                : ''
            }`}
            d="M78.90531,101.34961c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S79.76859,101.34961,78.90531,101.34961z M78.07621,99.38086
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S78.07621,98.29883,78.07621,99.38086z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A061'
                ? 'white-text'
                : elementOnHover === 'A061'
                ? 'white-text'
                : ''
            }`}
            d="M81.25199,99.15625v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10791,0.64258-0.32373,0.85449s-0.49463,0.31836-0.83643,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C81.30277,98.46875,81.25199,98.77637,81.25199,99.15625z M81.3223,100.16895c0,0.24805,0.06445,0.44727,0.19385,0.59766
			c0.13477,0.1582,0.31885,0.2373,0.55176,0.2373c0.46191,0,0.69287-0.27637,0.69287-0.8291
			c0-0.56445-0.23486-0.84668-0.70459-0.84668c-0.22705,0-0.40625,0.07715-0.53711,0.2334
			C81.38773,99.71582,81.3223,99.91895,81.3223,100.16895z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A061'
                ? 'white-text'
                : elementOnHover === 'A061'
                ? 'white-text'
                : ''
            }`}
            d="M84.88187,101.28223v-3.34277l-0.7207,0.48633v-0.4541l0.78516-0.49219h0.38379v3.80273H84.88187z"
          />
        </g>
        <g>
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A062'
                ? 'white-text'
                : elementOnHover === 'A062'
                ? 'white-text'
                : ''
            }`}
            d="M27.80033,101.28223l1.32129-4.13965h0.57129l1.33594,4.13965h-0.46582l-0.41602-1.27734h-1.47656
			l-0.40137,1.27734H27.80033z M29.40287,97.56738l-0.60645,2.04785h1.22461l-0.60645-2.04785H29.40287z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A062'
                ? 'white-text'
                : elementOnHover === 'A062'
                ? 'white-text'
                : ''
            }`}
            d="M32.58451,101.34961c-0.86328,0-1.29492-0.65625-1.29492-1.96875s0.43164-1.96875,1.29492-1.96875
			s1.29492,0.65625,1.29492,1.96875S33.44779,101.34961,32.58451,101.34961z M31.75541,99.38086
			c0,1.08203,0.27637,1.62305,0.8291,1.62305s0.8291-0.54102,0.8291-1.62305s-0.27637-1.62305-0.8291-1.62305
			S31.75541,98.29883,31.75541,99.38086z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A062'
                ? 'white-text'
                : elementOnHover === 'A062'
                ? 'white-text'
                : ''
            }`}
            d="M34.93119,99.15625v0.28027h0.01172c0.19141-0.29883,0.49512-0.44824,0.91113-0.44824
			c0.34766,0,0.61426,0.11621,0.7998,0.34863c0.16797,0.21191,0.25195,0.49121,0.25195,0.83984
			c0,0.35742-0.10791,0.64258-0.32373,0.85449s-0.49463,0.31836-0.83643,0.31836c-0.51367,0-0.86914-0.19922-1.06641-0.59766
			c-0.14453-0.29297-0.2168-0.74805-0.2168-1.36523c0-0.60938,0.08008-1.06934,0.24023-1.37988
			c0.20703-0.39648,0.55859-0.59473,1.05469-0.59473c0.375,0,0.65918,0.11133,0.85254,0.33496
			c0.14063,0.16113,0.21094,0.33203,0.21094,0.51465h-0.52148c0-0.10938-0.03711-0.21191-0.11133-0.30762
			c-0.10156-0.13086-0.25098-0.19629-0.44824-0.19629c-0.30078,0-0.51953,0.1582-0.65625,0.47559
			C34.98197,98.46875,34.93119,98.77637,34.93119,99.15625z M35.0015,100.16895c0,0.24805,0.06445,0.44727,0.19385,0.59766
			c0.13477,0.1582,0.31885,0.2373,0.55176,0.2373c0.46191,0,0.69287-0.27637,0.69287-0.8291
			c0-0.56445-0.23486-0.84668-0.70459-0.84668c-0.22705,0-0.40625,0.07715-0.53711,0.2334
			C35.06693,99.71582,35.0015,99.91895,35.0015,100.16895z"
          />
          <path
            className={`st6 ${
              sceneName === 'scene_08_krs_A062'
                ? 'white-text'
                : elementOnHover === 'A062'
                ? 'white-text'
                : ''
            }`}
            d="M37.41557,101.28223v-0.40137c0.36523-0.29883,0.70703-0.63672,1.02539-1.01367
			c0.48828-0.57715,0.73242-1.05957,0.73242-1.44531c0-0.19141-0.05078-0.3457-0.15234-0.46484
			c-0.11133-0.13281-0.27051-0.19922-0.47754-0.19922c-0.23242,0-0.40234,0.07422-0.50977,0.22168
			c-0.07617,0.10254-0.11426,0.22168-0.11426,0.35547h-0.50391c0.00781-0.22266,0.08301-0.41406,0.22559-0.57422
			c0.20117-0.23242,0.51172-0.34863,0.93164-0.34863c0.31641,0,0.57324,0.0957,0.77051,0.28711s0.2959,0.44727,0.2959,0.76758
			c0,0.44141-0.23145,0.93457-0.69434,1.47949c-0.16406,0.19336-0.48047,0.50293-0.94922,0.92871h1.69922v0.40723H37.41557z"
          />
        </g>
      </g>
      <g
        id="state_00000181772118692174762640000017467999192444162949_"
        className="switch"
        onClick={toggleSceneNameFuture}
      >
        <path
          id="plate_00000116197733759858099650000003808445106208805782_"
          className="st6"
          d="M169.53586,117.0297h-0.00002
		c-5.59163,0-10.16658-4.57496-10.16658-10.16658V91.3745c0-5.59162,4.57495-10.16658,10.16658-10.16658h0.00002
		c5.59161,0,10.16658,4.57496,10.16658,10.16658v15.48862C179.70244,112.45474,175.12747,117.0297,169.53586,117.0297z"
        />
        {isSceneNameFuture ? (
          <g id="_x38__00000016774709891080738020000007263401445980102793_">
            <path
              className="st6"
              d="M184.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H184.93462z"
            />
            <path
              className="st6"
              d="M190.49321,108.73438c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S191.64458,108.73438,190.49321,108.73438z M189.38774,106.10938
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.38774,104.66602,189.38774,106.10938z"
            />
            <path
              className="st6"
              d="M192.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H192.93462z"
            />
            <path
              className="st6"
              d="M196.93462,108.64453v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H196.93462z"
            />
            <path
              className="st6"
              d="M200.90727,106.92578v-0.54688h2.43359v0.54688H200.90727z"
            />
          </g>
        ) : (
          <g id="_x38__00000121995516067731841530000006761373441588209320_">
            <path
              className="st6"
              d="M185.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H185.32036z"
            />
            <path
              className="st6"
              d="M190.87895,93.97949c-1.15137,0-1.72656-0.875-1.72656-2.625s0.5752-2.625,1.72656-2.625
			s1.72656,0.875,1.72656,2.625S192.03032,93.97949,190.87895,93.97949z M189.77348,91.35449
			c0,1.44238,0.36816,2.16406,1.10547,2.16406s1.10547-0.72168,1.10547-2.16406c0-1.44336-0.36816-2.16406-1.10547-2.16406
			S189.77348,89.91113,189.77348,91.35449z"
            />
            <path
              className="st6"
              d="M193.32036,93.88965v-0.53516c0.4873-0.39844,0.94238-0.84961,1.36719-1.35156
			c0.65137-0.76953,0.97656-1.41211,0.97656-1.92676c0-0.25488-0.06738-0.46191-0.20313-0.62109
			c-0.14844-0.17676-0.36035-0.26465-0.63672-0.26465c-0.30957,0-0.53613,0.09863-0.67969,0.29492
			c-0.10156,0.1377-0.15234,0.2959-0.15234,0.47461h-0.67188c0.01074-0.29688,0.11035-0.55273,0.30078-0.76563
			c0.26855-0.31055,0.68262-0.46484,1.24219-0.46484c0.42188,0,0.76465,0.12695,1.02734,0.38281
			c0.2627,0.25488,0.39453,0.5957,0.39453,1.02344c0,0.58789-0.30859,1.24609-0.92578,1.97266
			c-0.21875,0.25781-0.64063,0.66992-1.26563,1.23828h2.26563v0.54297H193.32036z"
            />
            <path
              className="st6"
              d="M198.8477,93.88965v-4.45703l-0.96094,0.64844v-0.60547l1.04688-0.65625h0.51172v5.07031H198.8477z"
            />
          </g>
        )}

        {isSceneNameFuture ? (
          <g
            id="down_00000036232995491892513850000001805882644807975355_"
            className="switch"
            onClick={toggleSceneNameFuture}
          >
            <path
              className="st0"
              d="M169.53584,98.9855c-4.12483,0-7.39626,3.27142-7.39626,7.39626s3.27142,7.39626,7.39626,7.39626
			c3.98262,0,7.39627-3.27142,7.39627-7.39626S173.51846,98.9855,169.53584,98.9855z"
            />
          </g>
        ) : (
          <g id="up" className="switch" onClick={toggleSceneNameFuture}>
            <path
              className="st0"
              d="M169.53584,84.19297c-4.12483,0-7.39626,3.27142-7.39626,7.39626c0,4.12485,3.27142,7.39627,7.39626,7.39627
			c3.98262,0,7.39627-3.27142,7.39627-7.39627C176.93211,87.46439,173.51846,84.19297,169.53584,84.19297z"
            />
          </g>
        )}
      </g>
      <g id="view_00000011008754982513459450000006854482746866592687_">
        {apartmentNumber === 53 ? (
          <g id="A053_00000029748984935012439180000017649882892373545141_">
            <linearGradient
              id="A053cone_00000132785851819087315520000017942521876196161454_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="184.40488"
              x2="187.5202"
              y2="139.49487"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A053cone_00000163059699905339395710000000909997174956978617_"
              style={{
                fill: 'url(#A053cone_00000132785851819087315520000017942521876196161454_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M23.19704,17.25664c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L23.19704,17.25664z"
            />
            <circle
              id="A053_00000008119585277412108870000001011507341087147961_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 54 ? (
          <g id="A054_00000016768823704549463310000013325323917443295668_">
            <linearGradient
              id="A054cone_00000087372919851498999770000006970361767326560914_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="206.65987"
              x2="187.5202"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A054cone_00000080193155408691973670000013693187980831085969_"
              style={{
                fill: 'url(#A054cone_00000087372919851498999770000006970361767326560914_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M45.45204,17.25664c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,17.25664z"
            />
            <circle
              id="A054_00000064327388017792765350000013395715044959401383_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 55 ? (
          <g id="A055_00000105396230567208543680000016041531350504562083_">
            <linearGradient
              id="A055cone_00000065776129759780023140000011511018103361646498_"
              gradientUnits="userSpaceOnUse"
              x1="187.5202"
              y1="224.35446"
              x2="187.5202"
              y2="179.44446"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A055cone_00000117634977670594722620000002945272294420513664_"
              style={{
                fill: 'url(#A055cone_00000065776129759780023140000011511018103361646498_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M63.14662,17.25664c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L63.14662,17.25664z"
            />
            <circle
              id="A055_00000036249195775019790490000001552498320072784533_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 58 ? (
          <g id="A058_00000046309450217451751260000005564765537902825863_">
            <linearGradient
              id="A058cone_00000120557781362273244050000009018314887428807323_"
              gradientUnits="userSpaceOnUse"
              x1="189.84561"
              y1="297.95212"
              x2="189.84561"
              y2="253.0421"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A058cone_00000092435082136899055420000000754742683892126090_"
              style={{
                fill: 'url(#A058cone_00000120557781362273244050000009018314887428807323_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M136.74428,19.58205c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L136.74428,19.58205z"
            />
            <circle
              id="A058_00000143603130559583740720000010400375102752912514_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 60 ? (
          <g id="A060_00000011723812548185764770000013297433646575006381_">
            <linearGradient
              id="A060cone_00000167360527294718729120000004939327920158148262_"
              gradientUnits="userSpaceOnUse"
              x1="293.59818"
              y1="261.71448"
              x2="293.59818"
              y2="216.80447"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A060cone_00000130640488099901538690000004197582861563332248_"
              style={{
                fill: 'url(#A060cone_00000167360527294718729120000004939327920158148262_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M100.50665,123.33463c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L100.50665,123.33463z"
            />
            <circle
              id="A060_00000099626155079950793530000001663808272604369553_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 61 ? (
          <g id="A061_00000093148124557883138060000003190604594811121824_">
            <linearGradient
              id="A061cone_00000060714792417207998380000017044559732785862826_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="235.11095"
              x2="294.20319"
              y2="190.20094"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A061cone_00000023266036467887183970000001704678449597227423_"
              style={{
                fill: 'url(#A061cone_00000060714792417207998380000017044559732785862826_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M73.90311,123.93962c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L73.90311,123.93962z"
            />
            <circle
              id="A061_00000041267959426233430520000001870437277350510980_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 62 ? (
          <g id="A062_00000001632998541204586240000015396577375490532992_">
            <linearGradient
              id="A062cone_00000096740328062432754670000005715310870025358773_"
              gradientUnits="userSpaceOnUse"
              x1="294.20319"
              y1="206.65987"
              x2="294.20319"
              y2="161.74986"
              gradientTransform="matrix(0 1 1 0 -161.18782 -173.63857)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A062cone_00000035490193535774969700000010091558046789867392_"
              style={{
                fill: 'url(#A062cone_00000096740328062432754670000005715310870025358773_)',
              }}
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              d="
			M45.45204,123.93962c-0.37-0.89-0.58-1.86-0.58-2.89c0-1.04,0.21001-2.03,0.60001-2.92l-44.91-19.46v43.79L45.45204,123.93962z"
            />
            <circle
              id="A062_00000133521262074358868710000003197149163302123683_"
              className="st11"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_00000158717862141771022110000015793554772933978760_">
        <path
          id="plate_00000002369139136251261740000017936688458754755985_"
          className="st6"
          d="M169.53586,73.90963h-0.00002
		c-5.59163,0-10.16658-4.57497-10.16658-10.16658V29.3607c0-5.59162,4.57495-10.16658,10.16658-10.16658h0.00002
		c5.59161,0,10.16658,4.57496,10.16658,10.16658v34.38235C179.70244,69.33466,175.12747,73.90963,169.53586,73.90963z"
        />
        <path
          id="plate_00000109716667540828896840000013710780863168256180_"
          className="st7"
          d="M178.33493,65.20484l-0.24399-38.54957
		c0-2.71356-3.763-6.79157-8.55516-6.79157c-4.79228,0-8.79901,4.07801-8.79901,6.79157l0.24399,38.54957
		c0,2.71356,3.76274,6.69599,8.55502,6.69599C174.32794,71.90083,178.33493,67.9184,178.33493,65.20484z"
        />
        <g id="_x38__00000016786128927117387960000012448731758891890563_">
          <path
            className="st0"
            d="M169.5342255,49.0991211c-1.0556641,0-1.5839844-0.4833984-1.5839844-1.4501953
			c0-0.6982422,0.3173828-1.1152344,0.9521484-1.2509766v-0.015625c-0.5644531-0.171875-0.8466797-0.5839844-0.8466797-1.2373047
			c0-0.3876953,0.1298828-0.6987305,0.390625-0.9331055c0.2695313-0.2416992,0.6376953-0.362793,1.1035156-0.362793
			c0.4365234,0,0.7910156,0.1118164,1.0615234,0.3359375c0.2783203,0.2338867,0.4169922,0.5488281,0.4169922,0.9443359
			c0,0.3852539-0.1005859,0.6933594-0.3007813,0.9248047c-0.1552734,0.1796875-0.3398438,0.2890625-0.5537109,0.328125v0.015625
			c0.2314453,0.0444336,0.4335938,0.1523438,0.6044922,0.324707c0.2314453,0.234375,0.3476563,0.5512695,0.3476563,0.949707
			C171.1260223,48.6235352,170.5957489,49.0991211,169.5342255,49.0991211z M168.5713348,47.6088867
			c0,0.6860352,0.3291016,1.0292969,0.9863281,1.0292969c0.6318359,0,0.9472656-0.340332,0.9472656-1.0214844
			c0-0.6655273-0.3232422-0.9980469-0.9707031-0.9980469c-0.2998047,0-0.5351563,0.0869141-0.7070313,0.2612305
			C168.6572723,47.0541992,168.5713348,47.296875,168.5713348,47.6088867z M168.6768036,45.2475586
			c0,0.6069336,0.2988281,0.9101563,0.8964844,0.9101563c0.2587891,0,0.4619141-0.0795898,0.6103516-0.2392578
			c0.1494141-0.1591797,0.2236328-0.3803711,0.2236328-0.6630859c0-0.6303711-0.2910156-0.9453125-0.8730469-0.9453125
			c-0.2685547,0-0.4785156,0.0849609-0.6308594,0.2548828C168.7529755,44.7348633,168.6768036,44.9624023,168.6768036,45.2475586z"
          />
        </g>
        <path
          id="up_00000093135420731646516870000006290589623546332821_"
          className="st9"
          d="M169.1843,26.28777l-5.91881,7.69881
		c-0.22417,0.29158-0.0163,0.71358,0.35149,0.71358h11.83762c0.36778,0,0.57565-0.422,0.35149-0.71358l-5.91881-7.69881
		C169.70979,26.05692,169.36177,26.05692,169.1843,26.28777z"
        />
        <path
          id="down_00000023272118501985483260000007054124000737169842_"
          className="st9"
          d="M169.88727,64.64912l5.91881-7.69881
		c0.22417-0.29158,0.0163-0.71358-0.35149-0.71358h-11.83762c-0.36778,0-0.57565,0.422-0.35149,0.71358l5.91881,7.69881
		C169.36177,64.87997,169.70979,64.87997,169.88727,64.64912z"
        />
        <g id="down_00000117652772313359434550000016150368682571206797_">
          <path
            className="st0"
            d="M169.53584,55.39469c-4.12483,0-7.39626,3.27142-7.39626,7.39627s3.27142,7.39626,7.39626,7.39626
			c3.98262,0,7.39627-3.27142,7.39627-7.39626S173.51846,55.39469,169.53584,55.39469z"
          />
          <path
            className="st6"
            d="M174.37186,61.3686l0.42671,0.56894l-4.83601,3.27142c-0.14224,0.14223-0.42671,0.14223-0.56892,0
			l-4.83601-3.27142l0.42671-0.56894l4.55154,2.98696c0.14224,0.14223,0.28447,0.14223,0.42671,0L174.37186,61.3686z"
          />
        </g>
        <g
          id="up_00000161629206504842642560000011391800525661749666_"
          onClick={() => changeFloor('up')}
        >
          <path
            className="st0"
            d="M169.53584,22.57308c-4.12483,0-7.39626,3.27143-7.39626,7.39626s3.27142,7.39627,7.39626,7.39627
			c3.98262,0,7.39627-3.27142,7.39627-7.39627S173.51846,22.57308,169.53584,22.57308z"
          />
          <path
            className="st6"
            d="M174.37186,31.3917l0.42671-0.56894l-4.83601-3.27142c-0.14224-0.14224-0.42671-0.14224-0.56892,0
			l-4.83601,3.27142l0.42671,0.56894l4.55154-2.98695c0.14224-0.14224,0.28447-0.14224,0.42671,0L174.37186,31.3917z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  53: [27.79705, 14.48664],
  54: [50.05205, 14.48664],
  55: [67.74663, 14.48664],
  58: [141.34428, 16.81205],
  60: [105.10666, 120.56463],
  61: [78.50312, 121.16962],
  62: [50.05205, 121.16962],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e7e7e7;
  }
  .st2 {
    fill: #e7e7e7;
    stroke: #3f3f3c;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3f3f3c;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #d5d4cf;
    stroke: #3f3f3c;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: #ffffff;
  }
  .st9 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st10 {
    fill: url(#A053cone_00000026865166207922368280000011312151564029518527_);
  }
  .st11 {
    fill: #c65805;
  }
  .st12 {
    fill: url(#A054cone_00000000217633860064149400000005333764925870174378_);
  }
  .st13 {
    fill: url(#A055cone_00000034066402383371923890000007631342830308468411_);
  }
  .st14 {
    fill: url(#A058cone_00000027575205954260275760000016924115204437043102_);
  }
  .st15 {
    fill: url(#A060cone_00000040574583161577383350000001466835584384314800_);
  }
  .st16 {
    fill: url(#A061cone_00000000915084437941131100000014261841977804645557_);
  }
  .st17 {
    fill: url(#A062cone_00000135680371097307919480000006394036279304460699_);
  }

  #_x31_33_00000164511514952297587520000009917380297816848775_,
  #_x31_34_00000063608411035549729760000001245144089655726489_,
  #_x31_35_00000148645023103758215740000008878971228624982407_,
  #_x31_38_00000169545317567110637140000017938115892260140160_,
  #_x31_40_00000156578858643408984320000009567644132417164167_,
  #_x31_41_00000150072932780996024220000016767465916692874686_,
  #_x31_42_00000027570608014911752230000012566462189212508034_ {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: #c65805;
    }
  }

  #down_00000117652772313359434550000016150368682571206797_:hover {
    cursor: not-allowed;

    .st0 {
      fill: #b7b6b2;
    }

    .st6 {
      fill: var(--light);
    }
  }

  #up_00000161629206504842642560000011391800525661749666_:hover,
  #down_00000036232995491892513850000001805882644807975355_:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: #c65805;
    }

    .st6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: #c65805;
  }

  .on-hover {
    fill: #c65805;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .larger-text {
    transform: scale(1.3);
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  .sold {
    fill: #af6967;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
