import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  .st0 {
    display: none;
    fill: #d6d6cf;
  }
  .st1 {
    fill: #ffffff;
  }
  .st2 {
    fill: #df8a4f;
  }
  .st3 {
    fill: #c55b27;
  }
  .st4 {
    fill: #010101;
  }

  cursor: pointer;

  @media (max-width: 575px) {
    width: 25px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 20px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 20px;
  }
`

export default function JMSuomiLogo({ width = '40px', height = '100%' }) {
  return (
    <SVG
      id="jmsuomi-logo"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18.80924 27.06852"
      xmlSpace="preserve"
      enableBackground="new 0 0 18.80924 27.06852"
      width={width}
      height={height}
    >
      <g id="logo_00000041255888749136590960000001086134991300943546_">
        <rect
          x="-5.7648"
          y="-1.63358"
          className="st0"
          width="30.33884"
          height="30.33884"
        />
        <path
          className="st1"
          d="M9.40462,27.06852c-3.44268,0-6.00137-0.73405-7.60499-2.18198C0.20435,23.44413,0,21.34281,0,19.67318
		V7.39523c0-1.66963,0.20435-3.77095,1.79862-5.21246C3.40156,0.73427,5.96048,0,9.40462,0
		c3.44358,0,6.00261,0.73405,7.60589,2.18198c1.59438,1.44151,1.79873,3.54272,1.79873,5.21235v12.27885
		c0,1.67076-0.20435,3.77332-1.79851,5.21314C15.40543,26.33458,12.84662,27.06852,9.40462,27.06852z M9.40462,3.55173
		c-3.32676,0-4.70101,0.79319-5.22418,1.26588C3.75169,5.20536,3.55173,6.02446,3.55173,7.39523v12.27795
		c0,1.37076,0.19996,2.18987,0.62906,2.57796c0.52294,0.47213,1.89832,1.26566,5.22384,1.26566
		c3.3253,0,4.70158-0.79387,5.22609-1.26701c0.42741-0.38595,0.6268-1.20494,0.6268-2.5766V7.39433
		c0-1.37065-0.19962-2.18953-0.62827-2.57694C14.10642,4.34514,12.73115,3.55173,9.40462,3.55173z"
        />
        <path
          className="st2"
          d="M17.07752,19.6732c0,1.62446-0.23661,3.01321-1.21305,3.89518
		c-1.0921,0.98528-3.09443,1.72423-6.41575,1.72423s-5.32366-0.73895-6.41487-1.72423
		c-0.97644-0.88286-1.21393-2.27072-1.21393-3.89518V7.39525c0-1.62446,0.23749-3.01232,1.21393-3.89518
		c1.09122-0.98616,3.09002-1.72423,6.41487-1.72423s5.32366,0.73807,6.41575,1.72423
		c0.97645,0.88286,1.21305,2.26984,1.21305,3.8943V19.6732z"
        />
        <rect
          x="1.81991"
          y="11.68376"
          className="st3"
          width="1.76389"
          height="1.5875"
        />
        <rect
          x="12.13864"
          y="11.68376"
          className="st3"
          width="3.35138"
          height="1.5875"
        />
        <rect
          x="15.931"
          y="11.68376"
          className="st3"
          width="1.14653"
          height="1.5875"
        />
        <rect
          x="1.81991"
          y="9.65529"
          className="st3"
          width="3.43958"
          height="1.5875"
        />
        <rect
          x="13.72614"
          y="9.65529"
          className="st3"
          width="3.26319"
          height="1.5875"
        />
        <rect
          x="1.81991"
          y="15.74069"
          className="st3"
          width="1.76389"
          height="1.5875"
        />
        <rect
          x="15.931"
          y="15.74069"
          className="st3"
          width="1.14653"
          height="1.5875"
        />
        <path
          className="st3"
          d="M15.931,21.29693h1.0003c0.08033-0.52527,0.11827-1.05615,0.11345-1.5875H15.931V21.29693z"
        />
        <path
          className="st3"
          d="M3.31921,23.67818v0.15963c0.83305,0.59443,2.06128,1.06539,3.79235,1.28499v-1.44462H3.31921z"
        />
        <path
          className="st3"
          d="M7.64073,23.67818v1.4993c0.5504,0.05292,1.15036,0.08114,1.79456,0.08114
		c0.63762,0.0028,1.27497-0.02663,1.9096-0.08819v-1.49225H7.64073z"
        />
        <path
          className="st3"
          d="M11.87406,23.68171v1.42081c1.59448-0.22666,2.73926-0.67998,3.52777-1.24795v-0.17639L11.87406,23.68171z"
        />
        <rect
          x="4.11296"
          y="7.71501"
          className="st3"
          width="3.52777"
          height="1.5875"
        />
        <rect
          x="1.81991"
          y="7.71501"
          className="st3"
          width="1.76389"
          height="1.5875"
        />
        <polygon
          className="st3"
          points="12.13864,9.30251 15.49003,9.30251 15.49003,7.71501 15.42226,7.71501 12.13864,7.71501 	"
        />
        <rect
          x="15.931"
          y="7.71501"
          className="st3"
          width="1.14653"
          height="1.5875"
        />
        <path
          className="st3"
          d="M5.17129,5.68654H1.9426c-0.0867,0.52467-0.129,1.05572-0.12645,1.5875h3.35514V5.68654z"
        />
        <path
          className="st3"
          d="M13.72614,7.27404h3.20444c0.00428-0.53163-0.03628-1.06268-0.12125-1.5875h-3.08319V7.27404z"
        />
        <rect
          x="4.11296"
          y="3.65807"
          className="st3"
          width="3.52777"
          height="1.5875"
        />
        <path
          className="st3"
          d="M3.5838,3.65807H2.86977C2.4573,4.09134,2.19994,4.6286,2.04396,5.24557H3.5838V3.65807z"
        />
        <rect
          x="12.13864"
          y="3.65807"
          className="st3"
          width="3.35138"
          height="1.5875"
        />
        <path
          className="st3"
          d="M15.93273,5.24557h0.90207c-0.15598-0.61698-0.41334-1.15596-0.82581-1.5875H15.931L15.93273,5.24557z"
        />
        <path
          className="st3"
          d="M9.22823,3.3053V1.81746c-1.40578,0.01235-2.57074,0.1561-3.52777,0.39599V3.3053H9.22823z"
        />
        <path
          className="st3"
          d="M5.17129,2.35809C4.48059,2.5606,3.83013,2.88106,3.24866,3.3053h1.92264V2.35809z"
        />
        <path
          className="st3"
          d="M13.19698,3.3053V2.24697C12.2459,1.99649,11.0812,1.84127,9.6692,1.82187V3.3053H13.19698z"
        />
        <path
          className="st3"
          d="M15.49885,3.3053c-0.5378-0.39446-1.13665-0.69805-1.77271-0.8987v0.8987H15.49885z"
        />
        <path
          className="st3"
          d="M8.08171,5.33377h0.44097V4.71641H8.08171V5.33377z"
        />
        <path
          className="st3"
          d="M11.16851,4.71641v0.61736h0.44097V4.71641H11.16851z"
        />
        <path
          className="st3"
          d="M13.13008,5.68654h-1.96158v1.5875h2.02847v-1.5875H13.13008z"
        />
        <path
          className="st3"
          d="M8.52268,5.68654H5.70046v1.5875h2.82222L8.52268,5.68654z"
        />
        <path
          className="st3"
          d="M8.08171,7.71501v1.5875h0.44097v-1.5875H8.08171z"
        />
        <path
          className="st3"
          d="M11.16851,9.30251h0.44097v-1.5875h-0.44097V9.30251z"
        />
        <path
          className="st3"
          d="M7.19631,10.41437c0.12485,0.12223,0.26618,0.22746,0.42012,0.31282
		c0.44599,0.23916,0.90625,0.02166,0.90625-0.4168v-0.6551H5.70046v1.5875H6.53L7.19631,10.41437z"
        />
        <path
          className="st3"
          d="M11.16851,9.65529v0.86465c0.00154,0.24888-0.04998,0.49525-0.15116,0.72285h2.17963v-1.5875H11.16851z"
        />
        <path
          className="st3"
          d="M9.87484,12.29473c-0.53183,0.19012-1.21345,0.14691-1.79314,0v0.97652h3.52777v-1.5875h-0.8199
		C10.54659,11.96393,10.23057,12.175,9.87484,12.29473z"
        />
        <path
          className="st3"
          d="M7.39015,12.02868c-0.19697-0.10544-0.38827-0.22056-0.57315-0.34492H4.11296v1.5875h3.52777v-1.12099
		C7.55488,12.11441,7.47121,12.07382,7.39015,12.02868z"
        />
        <g>
          <path
            className="st1"
            d="M6.39732,11.42358l0.77155-0.98954c0.12329,0.1244,0.26284,0.2315,0.41484,0.31838
			c0.44038,0.24342,0.89486,0.02205,0.89486-0.42421v-5.6559H7.86122V3.61398h3.96874v1.05833h-0.70555v5.86757
			c0,0.76817-0.44038,1.42963-1.3009,1.74889c-0.77772,0.2884-1.90422,0.05292-2.51283-0.27428
			C6.99369,11.83766,6.68859,11.64027,6.39732,11.42358z"
          />
          <path
            className="st4"
            d="M9.00008,12.58842c-0.64066,0-1.31421-0.18103-1.76922-0.4256
			c-0.32422-0.18092-0.63773-0.3837-0.93401-0.60405l-0.13823-0.10285l0.99518-1.27636l0.13473,0.13597
			c0.11254,0.11355,0.23995,0.21134,0.37874,0.29065c0.16988,0.09395,0.35125,0.10454,0.48238,0.02692
			c0.10353-0.06106,0.16053-0.16932,0.16053-0.30484V4.8407H7.69286v-1.3951h4.30549v1.3951H11.2928v5.69912
			c0,0.87926-0.51415,1.57421-1.41076,1.90688C9.61517,12.54562,9.31146,12.58842,9.00008,12.58842z M6.63718,11.38979
			c0.24288,0.17281,0.49635,0.333,0.75545,0.47754c0.61745,0.3321,1.67493,0.52215,2.37225,0.2635
			c0.75692-0.28073,1.19108-0.86067,1.19108-1.591V4.50387h0.70555V3.78244H8.02969v0.72143h0.61734v5.82439
			c0,0.25584-0.11885,0.47269-0.32624,0.59503c-0.23511,0.13868-0.54107,0.13-0.81854-0.02354
			c-0.11141-0.06365-0.21596-0.13654-0.31284-0.2181L6.63718,11.38979z"
          />
        </g>
        <polygon
          className="st3"
          points="10.48208,14.24139 13.19698,14.24139 13.19698,13.71222 9.6692,13.71222 9.6692,15.29972
		10.16264,15.29972 	"
        />
        <polygon
          className="st3"
          points="8.45613,14.1532 8.8204,15.29972 9.22823,15.29972 9.22823,13.71222 5.70046,13.71222
		5.70046,14.1532 	"
        />
        <polygon
          className="st3"
          points="8.86513,15.74069 9.36946,17.32819 9.60776,17.32819 10.08522,15.74069 	"
        />
        <polygon
          className="st3"
          points="13.11138,15.74069 13.59664,17.32819 15.49003,17.32819 15.49003,15.74069 	"
        />
        <polygon
          className="st3"
          points="5.85037,15.74069 4.11296,15.74069 4.11296,17.32819 5.37551,17.32819 	"
        />
        <polygon
          className="st3"
          points="6.91571,17.68097 6.86202,17.68097 6.38742,19.26847 7.34095,19.26847 	"
        />
        <polygon
          className="st3"
          points="11.12392,17.68097 11.06589,17.68097 10.58609,19.26847 11.60805,19.26847 	"
        />
        <polygon
          className="st3"
          points="13.72614,17.68097 13.72614,17.95811 14.13316,19.26847 16.98933,19.26847 16.98933,17.68097 	"
        />
        <polygon
          className="st3"
          points="5.25949,17.84032 5.25949,17.68097 1.81991,17.68097 1.81991,19.26847 4.82289,19.26847 	"
        />
        <polygon
          className="st3"
          points="11.52128,21.03235 11.60948,21.03235 11.60948,19.70944 10.50957,19.70944 10.02891,21.29693
		11.52128,21.29693 	"
        />
        <polygon
          className="st3"
          points="4.29344,21.03235 4.68818,19.70944 4.11296,19.70944 4.11296,21.03235 	"
        />
        <polygon
          className="st3"
          points="7.42069,19.70944 6.23105,19.70944 5.83542,21.03235 6.49421,21.03235 6.49421,21.29693
		7.55254,21.29693 7.55254,20.19617 	"
        />
        <polygon
          className="st3"
          points="14.28447,19.70944 14.68899,21.03235 15.40183,21.03235 15.40183,21.29693 15.49003,21.29693
		15.49003,19.70944 	"
        />
        <polygon
          className="st3"
          points="11.52128,22.00249 11.52128,21.64971 9.90263,21.64971 9.80325,22.00249 9.6692,22.00249
		9.6692,23.3254 13.19698,23.3254 13.19698,22.00249 	"
        />
        <polygon
          className="st3"
          points="8.06991,22.00249 7.97965,21.64971 6.49421,21.64971 6.49421,22.00249 5.70046,22.00249
		5.70046,23.3254 9.22823,23.3254 9.22823,22.00249 	"
        />
        <path
          className="st3"
          d="M15.31364,21.73791v0.26458h-1.5875v1.32291h2.28291c0.38985-0.4376,0.63395-0.97486,0.77882-1.5875H15.31364z
		"
        />
        <path
          className="st3"
          d="M3.40741,22.00249v-0.26458H1.99755c0.14298,0.61264,0.38388,1.14989,0.76862,1.5875h2.40512v-1.32291H3.40741
		z"
        />
        <path
          className="st3"
          d="M3.40741,21.03235H3.5838v-1.32291H1.83434c-0.00338,0.53147,0.03601,1.06235,0.11778,1.5875h1.45528V21.03235
		z"
        />
        <polygon
          className="st3"
          points="5.17129,15.21153 5.17129,15.21153 5.17129,14.1532 5.17129,14.1532 5.17129,13.71222
		1.73172,13.71222 1.73172,15.29972 5.17129,15.29972 	"
        />
        <rect
          x="13.72614"
          y="13.71222"
          className="st3"
          width="3.26319"
          height="1.5875"
        />
        <g>
          <polygon
            className="st1"
            points="6.04336,15.16743 5.1272,15.16743 5.1272,14.19729 8.36185,14.19729 9.47567,17.51882
			10.53212,14.19729 13.68204,14.19729 13.68204,15.16743 12.90139,15.16743 14.68068,20.98825 15.35774,20.98825
			15.35774,22.04659 11.47719,22.04659 11.47719,20.98825 12.11119,20.98825 11.07612,17.46352 9.74332,22.04659 8.08214,22.04659
			6.90259,17.46352 5.88942,20.98825 6.53831,20.98825 6.53831,22.04659 3.45151,22.04659 3.45151,20.98825 4.30394,20.98825 		"
          />
          <path
            className="st4"
            d="M15.52619,22.21497h-4.2174v-1.3951h0.57746l-0.8093-2.75595l-1.20719,4.15104H7.95162l-1.05804-4.1106
			l-0.78057,2.7155h0.59368v1.3951H3.28305v-1.3951h0.89537l1.63888-5.48407H4.95877v-1.30689h3.52435l0.98831,2.94757
			l0.93761-2.94757h3.44144v1.30689h-0.72154l1.67639,5.48407h0.72087V22.21497z M11.64563,21.87814h3.54373v-0.72143h-0.63322
			l-1.88232-6.15773h0.83983v-0.63322H10.6553l-1.17531,3.69536l-1.2393-3.69536H5.2956v0.63322h0.97389l-1.84008,6.15773H3.61988
			v0.72143h2.74998v-0.72143H5.66578l1.24583-4.3341l1.30114,5.05553h1.40422l1.45841-5.01509l1.26081,4.29366h-0.69056V21.87814z"
          />
        </g>
        <path
          className="st4"
          d="M9.40462,25.46107c-2.9897,0-5.24693-0.61126-6.5278-1.76764
		c-1.11853-1.01151-1.26938-2.59992-1.26938-4.02025V7.39523c0-1.42033,0.15084-3.00874,1.26926-4.02014
		C4.15645,2.2187,6.41368,1.60745,9.40462,1.60745c2.99049,0,5.24806,0.61126,6.52859,1.76753
		c1.11786,1.01072,1.26859,2.59902,1.26859,4.01935v12.27885c0,1.42134-0.15073,3.01054-1.26859,4.02014
		C14.65144,24.84981,12.39387,25.46107,9.40462,25.46107z M9.40462,1.94428c-2.90611,0-5.08527,0.58118-6.30204,1.68067
		c-0.81189,0.73416-1.1583,1.86171-1.1583,3.77027v12.27795c0,1.90857,0.34641,3.03611,1.15841,3.77028
		c0.84918,0.76672,2.63191,1.68078,6.30193,1.68078c3.66956,0,5.45297-0.91407,6.30294-1.6809
		c0.81122-0.73281,1.1574-1.86024,1.1574-3.77016V7.39433c0-1.90846-0.34618-3.03578-1.15762-3.76937
		C14.48989,2.52546,12.3104,1.94428,9.40462,1.94428z"
        />
      </g>
    </SVG>
  )
}
